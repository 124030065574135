import React, { createContext, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setHandlePosition as setStoreHandlePosition } from '../../model/game';

const PlayerContext = createContext({ current: [0, 0, 0] });

const PlayerContextProvider = (props) => {
    const handlePosition = useRef([0, 0, 0]);
    const dispatch = useDispatch();

    const setHandlePosition = useCallback(
        (position) => {
            dispatch(setStoreHandlePosition(position));

            handlePosition.current = position;
        },
        [dispatch, handlePosition],
    );

    return (
        <PlayerContext.Provider value={[handlePosition, setHandlePosition]}>{props.children}</PlayerContext.Provider>
    );
};

export { PlayerContext, PlayerContextProvider };
