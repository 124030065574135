import { configureStore } from '@reduxjs/toolkit';
import modelGame from './game';
import modelEggrun from './eggrun';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

export const history = createBrowserHistory();

const store = configureStore({
    reducer: {
        router: connectRouter(history),
        game: modelGame.reducer,
        eggrun: modelEggrun.reducer,
    },
    devTools: true,
});

export default store;
