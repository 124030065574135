import React, { useCallback, useRef, useState } from 'react';
import styles from './ostern-game.module.scss';
import { useDeviceCheck } from './utils/useDeviceCheck';
import { CameraOverlay } from './components/CameraOverlay';
import { Gameplay } from './components/Gameplay';
import PlayTutorial from './PlayTutorial';
import { useDispatch, useSelector } from 'react-redux';
import { prepareGame } from './model/game';

const OsternGame = () => {
    useDeviceCheck(); // TODO only for local debugging
    const weekIndex = useSelector((state) => state.game.weekIndex); // FIXME likely triggers a lot of render calls
    const [tutorialIsOpen, setTutorialIsOpen] = useState(true);
    const dispatch = useDispatch();

    const gameStaryedRef = useRef(false);
    const startGame = useCallback(() => {
        if (gameStaryedRef.current === false) {
            gameStaryedRef.current = true;
            dispatch(prepareGame({ weekNumber: weekIndex }));
        }
    }, [dispatch, weekIndex]);

    const hideTutorial = useCallback(() => setTutorialIsOpen(false), []);

    return (
        <div className={styles.OsternGameContainer}>
            <div>
                {tutorialIsOpen && (
                    <PlayTutorial
                        isOpen={tutorialIsOpen}
                        hideTutorial={hideTutorial}
                        startGame={startGame}
                        weekNumber={weekIndex ? weekIndex : 1}
                    />
                )}
            </div>
            <div className={styles.wrapper}>
                <CameraOverlay />
                <Gameplay />
            </div>
        </div>
    );
};

export { OsternGame };
