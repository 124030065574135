import React, { Fragment, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import useTutorialSlider from './hooks/useTutorialSlider';
import ButtonPrimary from './elements/ButtonPrimary';

const tutorialGetSteps = (weekNumber, playType) => {
    let onboardingDone = false;
    let weeklyDone = false;
    let challengeDone = false;

    try {
        const tutorialOnboarding = window.localStorage.getItem(`tutorialOnboarding${weekNumber}`);
        const tutorialWeekly = window.localStorage.getItem(`tutorialWeekly${weekNumber}`);
        const tutorialChallenge = window.localStorage.getItem(`tutorialChallenge${weekNumber}`);
        if (tutorialOnboarding === 'yes') {
            onboardingDone = true;
        }
        if (tutorialWeekly === 'yes') {
            weeklyDone = true;
        }
        if (tutorialChallenge === 'yes') {
            challengeDone = true;
        }
    } catch {
        // local storage error
    }

    const steps = [];
    if (onboardingDone === false) {
        steps.push('step_balance', 'step_boosters');
    }
    if (weeklyDone === false) {
        steps.push(`week_${weekNumber}`);
    }
    if (challengeDone === false && playType === 'challenge') {
        steps.push('challenge');
    }

    steps.push('warning');

    return steps;
};

const tutorialSetSteps = (weekNumber, playType) => {
    try {
        window.localStorage.setItem(`tutorialOnboarding${weekNumber}`, 'yes');
        window.localStorage.setItem(`tutorialWeekly${weekNumber}`, 'yes');
        if (playType === 'challenge') {
            window.localStorage.setItem(`tutorialChallenge${weekNumber}`, 'yes');
        }
    } catch {
        // local storage error
    }
};

const TutorialStep = ({ steps, step, stepIndex, closeTutorial, nextSlide }) => {
    const { t } = useTranslation();
    const { locale } = useParams();

    const stepImage = step === 'challenge' ? `challenge-${locale}` : step;
    const ext = step === 'warning' ? 'png' : 'gif';
    const image = require(`./assets/tutorial/${stepImage}.${ext}`).default;
    const image2x = require(`./assets/tutorial/${stepImage}@2x.${ext}`).default;

    return (
        <div className={cx('eggrun-tutorial-slide-container', `eggrun-tutorial-slide-container-${steps.length}`)}>
            <div className="eggrun-tutorial-slide">
                <img
                    className="eggrun-tutorial-slide-image"
                    src={image}
                    srcSet={`${image} 1x, ${image2x} 2x`}
                    alt={t(`tutorial.${step}.title`)}
                />
                <div className="eggrun-tutorial-slide-text">
                    <div className="eggrun-tutorial-slide-title">{t(`tutorial.${step}.text`)}</div>
                    {t(`tutorial.${step}.note`) !== `tutorial.${step}.note` && (
                        <div className="eggrun-tutorial-slide-note">{t(`tutorial.${step}.note`)}</div>
                    )}
                </div>

                {stepIndex === steps.length - 1 ? (
                    <div className="eggrun-tutorial-slide-button">
                        <ButtonPrimary linkText={t('tutorial.button_start')} handleClick={closeTutorial} />
                    </div>
                ) : (
                    <div className="eggrun-tutorial-slide-button">
                        <ButtonPrimary linkText={t('tutorial.button_next')} handleClick={nextSlide} />
                    </div>
                )}
                <ul className="eggrun-tutorial-slide-progress">
                    {steps.length > 1 &&
                        steps.map((progressStep, progressIndex) => (
                            <li
                                key={`${step}-${progressIndex}`}
                                className={cx({ active: stepIndex === progressIndex })}
                            ></li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

const PlayTutorial = ({ isOpen, hideTutorial, startGame, weekNumber }) => {
    const { playType } = useSelector((state) => state.game);

    const steps = tutorialGetSteps(weekNumber, playType);

    const { enabledTransition, dragStart, sliderNext, refSlider, refTrack } = useTutorialSlider(
        steps.length,
        steps.length <= 1,
    );

    const closeTutorial = useCallback(() => {
        hideTutorial();
        tutorialSetSteps(weekNumber, playType);
        startGame();
    }, [startGame, weekNumber, playType, hideTutorial]);

    const nextSlide = useCallback(() => {
        sliderNext();
    }, [sliderNext]);

    useEffect(() => {
        if (steps.length <= 0) {
            closeTutorial();
        }
    }, [steps.length, closeTutorial]);

    return (
        <Fragment>
            <div className={cx('eggrun-tutorial-overlay', { 'eggrun-tutorial-overlay--open': isOpen })} />
            <div className={cx('eggrun-tutorial', { 'eggrun-tutorial--open': isOpen })} ref={refSlider}>
                <div
                    className={cx('eggrun-tutorial-slider', `eggrun-tutorial-slider-${steps.length}`, {
                        'eggrun-tutorial-slider--transition': enabledTransition,
                    })}
                    ref={refTrack}
                    onMouseDown={dragStart}
                    onTouchStart={dragStart}
                >
                    {steps.map((step, stepIndex) => (
                        <TutorialStep
                            steps={steps}
                            step={step}
                            stepIndex={stepIndex}
                            key={step}
                            closeTutorial={closeTutorial}
                            nextSlide={nextSlide}
                        />
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default PlayTutorial;
