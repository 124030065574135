import { useState, useRef, useEffect, useCallback } from 'react';

const useTutorialSlider = (slidesCount, disableSlider = false) => {
    const [slide, setSlide] = useState(1);

    const refSlider = useRef(null);
    const refTrack = useRef(null);

    const isDragging = useRef(false);
    const isMoving = useRef(false);
    const initialX = useRef(null);
    const diff = useRef(null);
    const initialTranlateX = useRef(null);

    const [enabledTransition, setEnabledTransition] = useState(true);
    const page = useRef(0);

    const sliderUpdate = useCallback(() => {
        if (!refTrack.current) {
            return;
        }

        if (page.current >= slidesCount - 1) {
            page.current = slidesCount - 1;
        } else if (page.current < 0) {
            page.current = 0;
        }
        setSlide(page.current + 1);

        const slideWidth = refSlider.current.getBoundingClientRect().width;
        const x = -1 * slideWidth * page.current;
        refTrack.current.style.transform = `translateY(-50%) translateX(${x}px)`;
    }, [slidesCount]);

    useEffect(() => {
        sliderUpdate();
        window.addEventListener('resize', sliderUpdate);
        return () => window.removeEventListener('resize', sliderUpdate);
    }, [sliderUpdate]);

    const sliderPrev = useCallback(() => {
        if (!refTrack.current || disableSlider) {
            return;
        }
        page.current -= 1;
        sliderUpdate();
    }, [disableSlider, sliderUpdate]);

    const sliderNext = useCallback(() => {
        if (!refTrack.current || disableSlider) {
            return;
        }
        page.current += 1;
        sliderUpdate();
    }, [disableSlider, sliderUpdate]);

    const sliderLast = useCallback(() => {
        if (!refTrack.current || disableSlider) {
            return;
        }
        page.current = slidesCount - 1;
        sliderUpdate();
    }, [disableSlider, slidesCount, sliderUpdate]);

    const dragStart = (e) => {
        if (disableSlider === true) {
            return;
        }

        setEnabledTransition(false);
        isDragging.current = true;
        initialX.current = null;
        isMoving.current = false;

        const slideWidth = refSlider.current.getBoundingClientRect().width;
        initialTranlateX.current = -1 * slideWidth * page.current;
        diff.current = 0;
    };

    useEffect(() => {
        const dragMove = (e) => {
            if (isDragging.current === true) {
                let x = null;
                if (e.type === 'touchmove') {
                    const touch = e.touches[0] || e.changedTouches[0];
                    x = touch.pageX;
                } else {
                    x = e.pageX;
                }

                if (initialX.current === null) {
                    initialX.current = x;
                }
                diff.current = x - initialX.current;
                if (!isMoving.current && Math.abs(diff.current) > 5) {
                    isMoving.current = true;
                }
                refTrack.current.style.transform = `translateY(-50%) translateX(${
                    initialTranlateX.current + diff.current
                }px)`;
            }
        };
        window.addEventListener('mousemove', dragMove);
        window.addEventListener('touchmove', dragMove);

        const dragEnd = (e) => {
            setEnabledTransition(true);
            if (isDragging.current === true) {
                isDragging.current = false;
                initialX.current = null;

                if (diff.current > 0) {
                    sliderPrev();
                } else if (diff.current < 0) {
                    sliderNext();
                }
            }
        };
        window.addEventListener('mouseup', dragEnd);
        window.addEventListener('touchend', dragEnd);
        window.addEventListener('touchcancel', dragEnd);
        return () => {
            window.removeEventListener('mousemove', dragMove);
            window.removeEventListener('touchmove', dragMove);

            window.removeEventListener('mouseup', dragEnd);
            window.removeEventListener('touchend', dragEnd);
            window.removeEventListener('touchcancel', dragEnd);
        };
    }, [sliderPrev, sliderNext]);

    return {
        slide,
        enabledTransition,
        sliderPrev,
        sliderNext,
        sliderLast,
        dragStart,
        refSlider,
        refTrack,
    };
};

export default useTutorialSlider;
