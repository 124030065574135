import React, { useMemo, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Euler, Object3D, Vector3 } from 'three';
import { useFrame } from 'react-three-fiber';
import lerp from 'lerp';
import { useCompoundBody, useBox } from '@react-three/cannon';
import { clearWindAction } from '../../model/game';
import { deg2rad, lerpAngle } from '../../utils/geometry';
import { PlayerContext } from '../Player';

/**
 * WIND_ANGLE is the base always used
 * STAGE_ANGLE is the additional max amount added in the stages
 */
const WIND_ANGLE = 20;
const STAGE_ANGLE = 20;

/**
 * Lerp speed is increased during mount to prevent transitioning into correct position in a fast way catapluting the egg away.
 * The tool should be instantly on the correct position.
 */
const LERP_ROTATE_DEFAULT = 0.35;
const LERP_ROTATE_MOUNT = 1;

const LERP_POSITION_DEFAULT = 0.5;
const LERP_POSITION_MOUNT = 1;

const MODEL_Y_OFFSET = 4;

// eslint-disable-next-line no-unused-vars
function rotateAroundPivot(point, pivotPoint, rotation) {
    point.sub(pivotPoint);

    // order matters here!!!
    point.applyAxisAngle(new Vector3(1, 0, 0), rotation.x);
    point.applyAxisAngle(new Vector3(0, 0, 1), rotation.z);
    point.applyAxisAngle(new Vector3(0, 1, 0), rotation.y);
    point.add(pivotPoint);
}

const Tool = ({ visible, shapes, model, onMount, onUnmount }) => {
    // Box at the tool and in the front to detect boosters
    const hitBoxArgs = useMemo(() => [2.5, 4, 4], []);

    // debug
    // /**
    //  *
    //  * @type {React.MutableRefObject<Mesh>}
    //  */
    // const ghostBoxRef = useRef();
    // const objectRef = useRef();
    // const rotationPointRef = useRef();
    // const pivotPointRef = useRef();
    // const backPointRef = useRef();

    // eslint-disable-next-line no-unused-vars
    const [boosterCheckRef, boosterCheckApi] = useBox(() => ({
        type: 'Kinematic',
        mass: 0,
        args: hitBoxArgs,
        collisionFilterGroup: 2,
        collisionFilterMask: 2,
        position: [0, 0, 0],
    }));

    // Physics
    const [lerpPosition, setLerpPosition] = useState(LERP_POSITION_DEFAULT);
    const [lerpRotation, setLerpRotation] = useState(LERP_ROTATE_DEFAULT);
    /**
     *
     * @type {Object3D}
     */
    const ghostBox = useMemo(() => new Object3D(), []);

    const [ref, api] = useCompoundBody(() => ({
        shapes,
    }));

    const [, setHandlePosition] = useContext(PlayerContext);
    let [wind, setWind] = useState(null);
    const windAction = useSelector((state) => state.game.windAction);
    const inverted = useSelector((state) => state.game.inverted);

    const dispatch = useDispatch();
    useFrame(({ camera, clock }) => {
        if (!api || !ref.current) {
            return;
        }

        if (!wind && windAction) {
            dispatch(clearWindAction());

            wind = {
                start: clock.elapsedTime,
                duration: windAction.duration / 1000,
                direction: windAction.option === 'left' ? 1 : -1,
                angle: WIND_ANGLE + lerp(0, STAGE_ANGLE, windAction.sensitivityModifier - 1),
            };
            setWind(wind);
        }

        if (wind && wind.start + wind.duration <= clock.elapsedTime) {
            wind = null;
            setWind(wind);
        }

        // Position ghost box in front of the camera
        ghostBox.rotation.copy(camera.rotation);
        ghostBox.position.copy(camera.position);
        ghostBox.translateY(1); // move down
        ghostBox.translateZ(-6); // move forward
        ghostBox.rotateX(deg2rad(45));

        // Store handle position for egg start point or to keep it glued
        setHandlePosition([ghostBox.position.x, ghostBox.position.y, ghostBox.position.z]);

        // Week 1: wind
        if (wind) {
            ghostBox.rotateZ(
                deg2rad(
                    wind.direction *
                        Math.sin((clock.elapsedTime - wind.start) / (wind.duration / Math.PI)) *
                        wind.angle,
                ),
            );
        }

        // Week 4: inversion
        if (inverted) {
            const distTo1 = camera.position.distanceTo(ghostBox.position);
            ghostBox.rotation.z = ghostBox.rotation.z * -1;
            /**
             * @type {Vector3}
             */
            const pivotPoint = ref.current.position.clone();
            /**
             * @type {Vector3}
             */
            const rotationPoint = pivotPoint.clone();
            // move the rotation point to where the real model is, not the center of the group which `ref` represents
            rotationPoint.y = rotationPoint.y - MODEL_Y_OFFSET;

            // rotate "real" position of tool around pivot point (rotation ancher of ghost box)
            // to avoid tool seeming like it is not fixed anymore
            rotateAroundPivot(rotationPoint, pivotPoint, ghostBox.rotation);

            const deltaPosX = rotationPoint.x - pivotPoint.x;
            const deltaPosY = rotationPoint.y - pivotPoint.y + MODEL_Y_OFFSET;
            const deltaPosZ = rotationPoint.z - pivotPoint.z;

            ghostBox.position.x = ghostBox.position.x - deltaPosX;
            ghostBox.position.y = ghostBox.position.y - deltaPosY;
            ghostBox.position.z = ghostBox.position.z - deltaPosZ;

            const distTo2 = camera.position.distanceTo(ghostBox.position);

            // keep distance to front of spoon
            const distDelta = distTo2 - distTo1;
            ghostBox.translateZ(distDelta);

            // // debug
            // if (rotationPointRef.current && pivotPointRef.current && backPointRef.current) {
            //     rotationPointRef.current.position.copy(rotationPoint);
            //     pivotPointRef.current.position.copy(pivotPoint);
            //
            //     rotationPointRef.current.rotation.copy(ref.current.rotation);
            //     pivotPointRef.current.rotation.copy(ref.current.rotation);
            //
            //     backPointRef.current.position.copy(ghostBox.position);
            //     backPointRef.current.rotation.copy(ghostBox.rotation);
            //
            //     backPointRef.current.translateZ(3);
            //     backPointRef.current.position.y = backPointRef.current.position.y - 3;
            //
            //     backPointRef.current.lookAt(camera.position);
            // }
        }

        // Cannon.js uses a diffrent axis so we need to reorder
        const rotationForCannon = new Euler().copy(ghostBox.rotation).reorder('XYZ');

        // Interpolate to make smooth movement
        if (ref.current?.position) {
            api.position.set(
                lerp(ref.current.position.x, ghostBox.position.x, lerpPosition),
                lerp(ref.current.position.y, ghostBox.position.y, lerpPosition),
                lerp(ref.current.position.z, ghostBox.position.z, lerpPosition),
            );
        }
        if (ref.current?.rotation) {
            api.rotation.set(
                lerpAngle(ref.current.rotation.x, rotationForCannon.x, lerpRotation),
                lerpAngle(ref.current.rotation.y, rotationForCannon.y, lerpRotation),
                lerpAngle(ref.current.rotation.z, rotationForCannon.z, lerpRotation),
            );
        }

        // Booster checker
        ghostBox.translateY(-2); // box position needs to be adjusted
        ghostBox.translateZ(1);
        boosterCheckApi.position.set(ghostBox.position.x, ghostBox.position.y, ghostBox.position.z);
        boosterCheckApi.rotation.set(rotationForCannon.x, rotationForCannon.y, rotationForCannon.z);

        if (lerpRotation === LERP_ROTATE_MOUNT) {
            setLerpRotation(LERP_ROTATE_DEFAULT);
        }
        if (lerpPosition === LERP_POSITION_MOUNT) {
            setLerpPosition(LERP_POSITION_DEFAULT);
        }

        // debug
        // if (ghostBoxRef.current) {
        //     ghostBoxRef.current.position.copy(ghostBox.position);
        //     ghostBoxRef.current.rotation.copy(ghostBox.rotation);
        //
        //     objectRef.current.position.copy(ref.current.position);
        //     objectRef.current.rotation.copy(ref.current.rotation);
        // }
    });

    // debug only
    /*const debugMode = useSelector((state) => state.game.debug.showCannonBoxes);
  const transparentMaterial = useMemo(
      () =>
          new MeshStandardMaterial({
              transparent: true,
              opacity: 0.6,
              wireframe: true,
              color: 0xff0000,
          }),
      [],
  );*/

    useEffect(() => {
        setLerpRotation(LERP_ROTATE_MOUNT);
        setLerpPosition(LERP_POSITION_MOUNT);
        onMount?.();

        return () => {
            onUnmount?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/*visible && debugMode && (
                <>
                    <mesh ref={boosterCheckRef}>
                        <boxBufferGeometry attach="geometry" args={hitBoxArgs} />
                        <meshStandardMaterial color="violet" wireframe={false} transparent={true} opacity={0.2} />
                    </mesh>

                    <mesh ref={ghostBoxRef} visible={true}>
                        <boxBufferGeometry attach="geometry" args={[2.5, 2.5, 2.5]} />
                        <meshStandardMaterial color="violet" wireframe={false} transparent={true} opacity={0.2} />
                    </mesh>

                    <mesh ref={objectRef} visible={false}>
                        <boxBufferGeometry attach="geometry" args={[0.5, 0.5, 0.5]} />
                        <meshStandardMaterial color="red" wireframe={false} transparent={true} opacity={0.4} />
                    </mesh>
                    <mesh ref={rotationPointRef}>
                        <boxBufferGeometry attach="geometry" args={[0.3, 0.3, 0.3]} />
                        <meshStandardMaterial color="orange" wireframe={false} transparent={true} opacity={1} />
                    </mesh>
                    <mesh ref={pivotPointRef}>
                        <boxBufferGeometry attach="geometry" args={[0.35, 0.35, 0.35]} />
                        <meshStandardMaterial color="blue" wireframe={false} transparent={true} opacity={1} />
                    </mesh>
                    <mesh ref={backPointRef}>
                        <boxBufferGeometry attach="geometry" args={[0.3, 0.3, 0.3]} />
                        <meshStandardMaterial color="violet" wireframe={false} transparent={true} opacity={1} />
                    </mesh>
                </>
            ) */}

            <group ref={ref} dispose={null} visible={visible}>
                {/*debugMode &&
                    shapes
                        .filter(({ type }) => type === 'Box')
                        .map((shape, index) => (
                            <mesh
                                key={index}
                                material={transparentMaterial}
                                position={shape.position}
                                rotation={shape.rotation}
                            >
                                <boxBufferGeometry args={shape.args} />
                            </mesh>
                        ))*/}
                <primitive object={model} />
            </group>
        </>
    );
};

export { Tool };
