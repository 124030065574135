import React from 'react';
import { boosterPositions, worldSize } from '../../config/game';
import { mapPercentagePositionToWorld } from '../../utils/geometry';

const DebugWorld = () => {
    const cylinderArgs = [0.3, 0.3, 5, 16];
    const sphereArgs = [0.5, 6];

    return (
        <>
            {/* Ground plane */}
            <gridHelper args={[worldSize, worldSize]} position={[0, -5, 0]} />
            <axesHelper />
            {/* Green cylinder at the start point for reference */}
            <mesh position={[0, -1.5, 0]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'green'} wireframe={false} />
            </mesh>
            {/* Orange cylinders for reference */}
            <mesh position={[worldSize / 4, -1.5, worldSize / 4]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'cyan'} wireframe={false} />
            </mesh>
            <mesh position={[worldSize / 4, -1.5, worldSize / -4]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'orange'} wireframe={false} />
            </mesh>
            <mesh position={[worldSize / -4, -1.5, worldSize / 4]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'orange'} wireframe={false} />
            </mesh>
            <mesh position={[worldSize / -4, -1.5, worldSize / -4]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'red'} wireframe={false} />
            </mesh>
            <mesh position={[0, -1.5, worldSize / 4]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'orange'} wireframe={false} />
            </mesh>
            <mesh position={[0, -1.5, worldSize / -4]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'black'} wireframe={false} />
            </mesh>
            <mesh position={[worldSize / -4, -1.5, 0]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'orange'} wireframe={false} />
            </mesh>
            <mesh position={[worldSize / 4, -1.5, 0]}>
                <cylinderGeometry args={cylinderArgs} />
                <meshStandardMaterial color={'orange'} wireframe={false} />
            </mesh>

            {/* Booster positions */}
            {boosterPositions.map(mapPercentagePositionToWorld).map((pos) => (
                <mesh key={`${pos.x}-${pos.y}`} position={[pos.x, -4, pos.y]}>
                    <sphereGeometry args={sphereArgs} />
                    <meshStandardMaterial color={'violet'} wireframe={false} />
                </mesh>
            ))}
        </>
    );
};

export { DebugWorld };
