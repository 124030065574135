import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Booster } from '../Booster';

const Boosters = () => {
    const boosters = useSelector((state) => state.game.boosters);

    const boostersElement = useMemo(
        () =>
            boosters?.map(({ booster, position }) => {
                return (
                    <Booster key={`${booster.id}-${position.x}x${position.y}`} booster={booster} position={position} />
                );
            }),
        [boosters],
    );
    if (!boostersElement) {
        return null;
    }

    return boostersElement;
};

export { Boosters };
