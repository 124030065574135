import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const EggrunKeyvisual = ({ small = false }) => {
    const { t } = useTranslation();
    const locale = 'de';

    const textTop = require(`../assets/keyvisual/text-top-${locale}.svg`).default;
    const textCenter = require(`../assets/keyvisual/text-center-${locale}.png`).default;
    const textCenter2x = require(`../assets/keyvisual/text-center-${locale}@2x.png`).default;

    return (
        <div className={cx('eggrun-keyvisual', { 'eggrun-keyvisual--small': small })}>
            <div className="eggrun-keyvisual-spoon eggrun-keyvisual-spoon-1" />
            <div className="eggrun-keyvisual-spoon eggrun-keyvisual-spoon-2" />
            <div className="eggrun-keyvisual-egg-left" />
            <div className="eggrun-keyvisual-egg-right" />
            <div className="eggrun-keyvisual-egg-front" />
            <img className="eggrun-keyvisual-text-top" src={textTop} alt={t('parts.keyvisual.text_top_alt')} />
            <img
                className={`eggrun-keyvisual-text-center eggrun-keyvisual-text-center-${locale}`}
                src={textCenter}
                srcSet={`${textCenter} 1x, ${textCenter2x} 2x`}
                alt={t('parts.keyvisual.text_center_alt')}
            />
        </div>
    );
};

export default EggrunKeyvisual;
