import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useProgress } from '@react-three/drei';
import cx from 'classnames';

import { eggrunScrollEnable } from '../model/eggrun';

import EggrunKeyvisual from '../parts/EggrunKeyvisual';

// a minimum delay of 2s is needed to make login work correctly
const MIN_LOADING_TIMEOUT = 2000;

const EggrunLoading = () => {
    const dispatch = useDispatch();
    const { progress } = useProgress();

    const [show, setShow] = useState(true);
    const [allAssetsLoaded, setAllAssetsLoaded] = useState(false);
    const [hasReachedMinTimeout, setHasReachedMinTimeout] = useState(false);

    useEffect(() => {
        if (progress === 100) {
            setAllAssetsLoaded(true);
        }
    }, [progress]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setHasReachedMinTimeout(true);
            setShow(false);
        }, MIN_LOADING_TIMEOUT);

        return () => {
            dispatch(eggrunScrollEnable());
            clearTimeout(timeout);
        };
    }, [dispatch]);

    useEffect(() => {
        if (hasReachedMinTimeout && allAssetsLoaded) {
            dispatch(eggrunScrollEnable());
            setShow(false);
        }
    }, [dispatch, hasReachedMinTimeout, allAssetsLoaded]);

    return (
        <div className={cx('eggrun-loading', { 'eggrun-loading--hide': !show })}>
            <div className="eggrun-loading-keyvisual">
                <EggrunKeyvisual />
            </div>
            <div className="eggrun-loading-progress">
                <span style={{ transform: `scaleX(${(progress - 10) / 100})` }}></span>
            </div>
        </div>
    );
};

export default EggrunLoading;
