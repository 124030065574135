import React from 'react';

const EggrunFooter = () => {
    return (
        <div className="eggrun-footer">
            <div className="eggrun-footer-egg-left" />
            <div className="eggrun-footer-egg-middle" />
            <div className="eggrun-footer-egg-right" />
        </div>
    );
};

export default EggrunFooter;
