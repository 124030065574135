import React, { Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ThreeSceneSetup from '../ThreeSceneSetup';
import { Ground } from '../Ground';
import { Boosters } from '../Boosters';
import { HUD } from '../HUD';
import { Sounds } from '../Sounds';
import { BoosterAnimations, EventAnimation, CountdownAnimation } from '../Animation';
import { FPSStats } from '../FPSStats';
import { DebugWorld } from '../DebugWorld/debugworld';
import { Player } from '../Player';
import { AssetsContextProvider } from './AssetsContext';
import { eventType } from '../../config/game';

const Gameplay = () => {
    const debug = useSelector((state) => state.game.debug);

    const running = useSelector((state) => state.game.running);
    const themeType = useSelector((state) => state.game.theme.type);
    const hasEventAnimation = useMemo(() => {
        return themeType === eventType.wind || themeType === eventType.obstruct;
    }, [themeType]);

    return (
        <>
            <ThreeSceneSetup>
                {/* Do not remove FPSStats component from scene, it is responsible to trigger the rendering */}
                <FPSStats visible={debug.showFpsStats} />
                {debug.showWorld && <DebugWorld />}
                <Ground />
                <Suspense fallback={null}>
                    <AssetsContextProvider>
                        <Player />
                        <Boosters />
                    </AssetsContextProvider>
                </Suspense>
            </ThreeSceneSetup>
            <HUD />
            <Sounds />
            {!running && <CountdownAnimation />}
            <BoosterAnimations />
            {hasEventAnimation && <EventAnimation />}
        </>
    );
};

export { Gameplay };
