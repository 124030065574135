import React, { useEffect, useMemo, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useCylinder } from '@react-three/cannon';
import { deg2rad } from '../../utils/geometry';

import { MeshStandardMaterial } from 'three';
import { useFrame } from 'react-three-fiber';
import { PlayerContext } from '../Player';
import { AssetsContext, disposeModel } from '../Gameplay/AssetsContext';

const EGG_MASS = 50;

const Egg = ({ visible, reinitialize, onReinitialized }) => {
    const {
        models: { egg: originalModel },
        normalMaps: { egg: normalMap },
        ormMaps: { egg: ormMap },
        textures: { egg: texture },
    } = useContext(AssetsContext);

    const model = useMemo(() => originalModel.clone(true), [originalModel]);

    const mesh = useMemo(() => {
        let mesh = null;
        model.traverse((node) => {
            if (node.isMesh) {
                mesh = node;
            }
        });

        return mesh;
    }, [model]);

    const eggMaterial = useMemo(() => {
        if (!mesh) {
            return;
        }
        return new MeshStandardMaterial({
            color: mesh.material.color,
            map: texture,
            metalness: 1, // required to make metalnessMap working
            envMapIntensity: 0.5,

            normalMap,
            aoMap: ormMap,
            roughnessMap: ormMap,
            metalnessMap: ormMap,
        });
    }, [mesh, normalMap, ormMap, texture]);

    // Prepare and assign material
    // TODO move materials to global place for reusing (low prio, possible improvement)
    useEffect(() => {
        if (!mesh || !eggMaterial) {
            return;
        }

        if (mesh.rotation.x === 0) {
            mesh.translateY(-0.5); // move a bit back to better match the cylinder
            mesh.rotateX(deg2rad(90)); // make the egg look up to match physics cylinder
        }

        mesh.material = eggMaterial;
    }, [mesh, eggMaterial]);

    const cameraRotation = useSelector((state) => state.game.cameraRotation);
    const [handlePosition] = useContext(PlayerContext);

    const getStartPosition = useCallback(
        (reinit = false) => {
            if (!handlePosition.current) {
                return [0, 0, -5];
            }
            return [
                handlePosition.current[0],
                // drop it from slightly lower when re-initializing as the user night be more in motion then
                handlePosition.current[1] - (reinit ? 1.5 : 1.2),
                handlePosition.current[2],
            ];
        },
        [handlePosition],
    );

    /**
     * Downsides of the cylinder:
     * - Bottom/Top is flat so the egg can stand up easily
     */
    const cylinderGeometry = [
        0.6, // radius top
        1, // radius bottom
        2.5, // height
        7, // segments, must be odd to have a flat surface at the bottom, less segments mean less rolling
    ];

    /**
     * @param {Api} api
     */
    const [ref, api] = useCylinder(() => {
        return {
            // type: 'Static',
            mass: EGG_MASS,
            args: cylinderGeometry,
            position: getStartPosition(),
            angularDamping: 0.01, // Increased dampening to minimize rotation
            linearDamping: 0.5,
            rotation: [deg2rad(-90), 0, cameraRotation.y],
        };
    });

    useFrame(() => {
        if (!visible) {
            // if egg is not visible, keep it above the spoon to avoid the game ending before it even has been started by the user
            api.position.set(handlePosition.current[0], handlePosition.current[1], handlePosition.current[2]);
            return;
        }
    });

    useEffect(() => {
        if (reinitialize) {
            const startPosition = getStartPosition(true);
            api.position.set(...startPosition);
            api.rotation.set(deg2rad(-90), 0, cameraRotation.y);
            api.velocity.set(0, 0, 0);
            api.angularVelocity.set(0, 0, 0);

            onReinitialized();
        }
    }, [reinitialize, api, cameraRotation, getStartPosition, onReinitialized]);

    useEffect(() => {
        const currentModel = model;
        return () => {
            disposeModel(currentModel);
        };
    }, [model]);

    //const debugMode = useSelector((state) => state.game.debug.showCannonBoxes);
    return (
        <group ref={ref} dispose={null} visible={visible}>
            {/*debugMode && (
                <mesh ref={ref}>
                    <cylinderGeometry args={cylinderGeometry} />
                    <meshBasicMaterial color={0x0000ff} wireframe={true} />
                </mesh>
            )*/}
            <primitive object={model} />
        </group>
    );
};

export { Egg };
