import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { ReactReduxContext, Provider, useSelector } from 'react-redux';
import { Canvas, useLoader, useThree } from 'react-three-fiber';
import { Physics } from '@react-three/cannon';
import { DeviceOrientationMovementControls } from '../DeviceOrientationMovementControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { UnsignedByteType, PMREMGenerator, PerspectiveCamera, Cache } from 'three';
import environmentMapTexture from '../../assets/reading_room_128.hdr';
import { ResizeObserver } from '@juggle/resize-observer';
import { getGPUTier } from 'detect-gpu';

Cache.enabled = true;

useLoader.preload(RGBELoader, environmentMapTexture);

const MIN_GPU_TIER_FOR_DEVICE_PIXEL_RATIO = 2;

const Environment = () => {
    const { gl, scene } = useThree();
    const cameraEnabled = useSelector((state) => !state.game.debug.disableCamera);
    const envMap = useLoader(RGBELoader, environmentMapTexture, (loader) => {
        loader.setDataType(UnsignedByteType);
    });

    useEffect(() => {
        const generator = new PMREMGenerator(gl);
        const target = generator.fromEquirectangular(envMap);
        envMap.dispose();
        generator.dispose();

        scene.environment = target.texture;
        if (!cameraEnabled) {
            // show environment as background for nicer debugging view
            scene.background = target.texture;
        }

        return () => (scene.environment = scene.background = null);
    }, [envMap, gl, scene, cameraEnabled]);

    return null;
};

const resizeOptions = { polyfill: ResizeObserver, scroll: false };

const ThreeSceneSetup = ({ children, onClick }) => {
    const camera = useMemo(() => {
        const camera = new PerspectiveCamera(80, 1, 0.1);
        camera.position.set(0, 4, 0);
        camera.up.set(0, 0, 1);

        return camera;
    }, []);

    const [pixelRatio, setPixelRatio] = useState(1);
    useEffect(() => {
        const fetch = async () => {
            const gpuTier = await getGPUTier();
            // Example output:
            // {
            //   "tier": 1,
            //   "isMobile": false,
            //   "type": "BENCHMARK",
            //   "fps": 21,
            //   "gpu": "intel iris graphics 6100"
            // }

            if (gpuTier.tier >= MIN_GPU_TIER_FOR_DEVICE_PIXEL_RATIO) {
                setPixelRatio(window.devicePixelRatio);
            }
        };
        fetch();
    }, []);

    return (
        // it's here because Canvas lost context. to know more, see https://github.com/pmndrs/react-three-fiber/issues/43
        <ReactReduxContext.Consumer>
            {({ store }) => (
                <Canvas
                    colorManagement
                    pixelRatio={pixelRatio}
                    sRGB
                    concurrent
                    camera={camera}
                    onClick={onClick}
                    resize={resizeOptions}
                >
                    <Provider store={store}>
                        <DeviceOrientationMovementControls />
                        <ambientLight intensity={2} color={0x404040} />
                        <pointLight intensity={1} color={0x404040} />
                        <Suspense fallback={null}>
                            <Environment />
                        </Suspense>
                        <Physics
                            iterations={10}
                            tolerance={0}
                            defaultContactMaterial={{
                                friction: 0.001, // friciton must be very low to have egg turn with spoon on device rotation
                                restitution: 0,
                                contactEquationStiffness: 1e7,
                                contactEquationRelaxation: 3,
                                frictionEquationStiffness: 1e7,
                                frictionEquationRelaxation: 3,
                            }}
                            gravity={[0, -40, 0]}
                            allowSleep={false}
                        >
                            {children}
                        </Physics>
                    </Provider>
                </Canvas>
            )}
        </ReactReduxContext.Consumer>
    );
};

export default ThreeSceneSetup;
