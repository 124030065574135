import React from 'react';
import { useTranslation } from 'react-i18next';

const EggrunDesktop = () => {
    const { t } = useTranslation();

    return (
        <div className="eggrun-desktop">
            <div className="eggrun-desktop-content">
                <svg
                    className="eggrun-desktop-icon"
                    width="16"
                    height="26"
                    viewBox="0 0 16 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 3.03533C0 1.35896 1.36447 0 3.04762 0H12.9524C14.6355 0 16 1.35896 16 3.03533V22.9647C16 24.641 14.6355 26 12.9524 26H3.04762C1.36447 26 0 24.641 0 22.9647V3.03533ZM2.52632 2.51613H13.4737V20.9677H2.52632V2.51613ZM8.00004 24.7419C8.69767 24.7419 9.2632 24.1786 9.2632 23.4838C9.2632 22.789 8.69767 22.2258 8.00004 22.2258C7.30242 22.2258 6.73689 22.789 6.73689 23.4838C6.73689 24.1786 7.30242 24.7419 8.00004 24.7419Z"
                        fill="#FF6600"
                    />
                </svg>
                <div className="eggrun-desktop-title">{t('desktop.title')}</div>
                <div className="eggrun-desktop-text">{t('desktop.text')}</div>
            </div>
        </div>
    );
};

export default EggrunDesktop;
