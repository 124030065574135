import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useBox } from '@react-three/cannon';

import { useAnimations } from '@react-three/drei';
import { AnimationUtils, LoopOnce, LoopRepeat, MeshStandardMaterial } from 'three';
import { useDispatch } from 'react-redux';
import { collectBooster } from '../../model/game';
import { AssetsContext, disposeModel } from '../Gameplay/AssetsContext';

const animationClips = [
    { name: 'Intro', start: 1, end: 85, fps: 24 },
    //{ name: 'Idle', start: 100, end: 140, fps: 24 },
    { name: 'Jump', start: 160, end: 204, fps: 24 },
    //{ name: 'Open', start: 240, end: 246, fps: 24 },
    { name: 'Outro', start: 260, end: 267, fps: 24 },
];

const BOOSTER_SCALE = [0.8, 0.8, 0.8];
const BOOSTER_HEIGHT_IN_ROOM = -5;

/**
 * booster: Type of booster (see config)
 * position: Position of the booster
 */
const Booster = ({ booster, position }) => {
    const {
        models: { booster: originalModel },
        normalMaps: { booster: normalMap },
        ormMaps: { booster: ormMap },
        textures: { boosters: textures },
    } = useContext(AssetsContext);

    const texture = useMemo(() => textures[booster.id % textures.length], [textures, booster.id]);

    // Prepare and assign material
    // TODO move materials to global place for reusing (low prio, possible improvement)
    const model = useMemo(() => {
        return originalModel.clone(true);
    }, [originalModel]);

    useEffect(() => {
        let mesh = null;
        model.traverse((node) => {
            if (node.isMesh) {
                mesh = node;
            }
        });

        mesh.material = new MeshStandardMaterial({
            color: mesh.material.color,
            map: texture,
            metalness: 1, // required to make metalnessMap working
            envMapIntensity: 0.5,

            normalMap,
            aoMap: ormMap,
            roughnessMap: ormMap,
            metalnessMap: ormMap,
        });
    }, [model, texture, normalMap, ormMap]);

    // Make the egg a physics object with a low mass
    const dispatch = useDispatch();
    const [collided, setCollided] = useState(false);
    const hitBoxArgs = useMemo(() => [2.5, 10, 2.5], []);
    const [ref] = useBox(() => ({
        type: 'Dynamic',
        mass: 0,
        args: hitBoxArgs,
        position: [position.x, BOOSTER_HEIGHT_IN_ROOM, position.y],
        collisionFilterGroup: 2,
        collisionFilterMask: 2,
        onCollide: (event) => {
            setCollided(true);
        },
    }));

    useEffect(() => {
        if (!collided) {
            return;
        }

        dispatch(collectBooster(booster));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collided]); // Only depencency MUST be collided!

    // prepare animations
    const animations = useMemo(() => {
        const fullClip = originalModel.animations[0].clone();

        return animationClips.map(({ name, start, end, fps }) =>
            AnimationUtils.subclip(fullClip, name, start, end, fps),
        );
    }, [originalModel]);

    const { actions, ref: animationRef } = useAnimations(animations);

    useEffect(() => {
        if (collided) {
            actions.Jump.stop();
            actions.Outro.setLoop(LoopOnce).play();
            return;
        }

        actions.Intro.setLoop(LoopOnce).play();
        actions.Jump.setLoop(LoopRepeat).play();
    });

    useEffect(() => {
        const currentModel = model;
        return () => {
            disposeModel(currentModel);
        };
    }, [model]);

    //const debugMode = useSelector((state) => state.game.debug.showCannonBoxes);
    return useMemo(
        () => (
            <group ref={ref} dispose={null}>
                <group ref={animationRef} scale={BOOSTER_SCALE}>
                    <primitive object={model} />
                </group>
                {/*debugMode && (
                // debug hitbox for booster collision
                <mesh visible={true}>
                    <boxBufferGeometry attach="geometry" args={hitBoxArgs} />
                    <meshStandardMaterial color="violet" wireframe={false} transparent={true} opacity={0.5} />
                </mesh>
            )*/}
            </group>
        ),
        [model, animationRef, ref],
    );
};

export { Booster };
