import React from 'react';
import { LinkCms } from '../features/elements';

const ButtonPrimary = ({ linkType = null, linkUrl = null, linkText, handleClick = null }) => {
    return (
        <LinkCms
            type={linkType}
            url={linkUrl}
            className="eggrun-button eggrun-button--primary"
            internalOnClick={handleClick}
        >
            {linkText}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" className="eggrun-button-arrow">
                <path
                    d="M.8239,9.99H13.8935L8.402,15.28,9.86572,16.7l7.26668-7a.97019.97019,0,0,0,0-1.41l-7.26668-7L8.402,2.7,13.8935,7.99H.8239Z"
                    fill="#fff"
                />
            </svg>
        </LinkCms>
    );
};

export default ButtonPrimary;
