import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useInterval from '@use-it/interval';
import cn from 'classnames';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Messages } from '../Messages';
import { gameDuration, gameInterval } from '../../config/game';
import { rad2deg } from '../../utils/geometry';
import { setPositionCorrect, start, tick, prepareGame } from '../../model/game';
import iconBoosterLadle from '../../assets/iconBoosterLadle.svg';
import iconArrow from '../../assets/iconArrow.svg';
import styles from './hud.module.scss';
import { useHistory } from 'react-router-dom';

const COUNTDOWN_TIME = 3;

const CurrentScore = () => {
    const { t } = useTranslation();
    const points = useSelector((state) => state.game.points);
    const modifier = useSelector((state) => state.game.modifier);
    const modifierDuration = useSelector((state) => state.game.modifierDuration);

    return (
        <div className={styles.points}>
            <h4>{t('game.hud.points')}</h4>
            <span className="value">{points}</span>
            {modifier && (
                <ModifierTimer name={modifier} duration={modifierDuration / 1000} className={styles.modifier} />
            )}
        </div>
    );
};

const RemainingTime = () => {
    const { t } = useTranslation();
    const time = useSelector((state) => {
        if (!state.game.running) {
            return 0;
        }

        const seconds = Math.ceil((gameDuration - (Date.now() - state.game.startTime)) / 1000);
        const date = new Date(0);
        date.setSeconds(seconds);
        return date.toISOString().substr(14, 5);
    });

    return (
        <div className={styles.timer}>
            <h4>{t('game.hud.time.remaining')}</h4>
            <span className="value">{time}</span>
        </div>
    );
};

const ModifierTimer = ({ name, duration, className }) => {
    return (
        <div className={className}>
            <CountdownCircleTimer
                key={duration}
                isPlaying
                duration={duration}
                colors="#FF6600"
                size={40}
                strokeWidth={3}
            >
                {({ remainingTime }) => {
                    return <>&times;{name}</>;
                }}
            </CountdownCircleTimer>
        </div>
    );
};

const PhysicsTimer = ({ icon, start, duration }) => {
    const remainingTime = useMemo(() => {
        return start + duration - Date.now();
    }, [start, duration]);

    return (
        <div className={styles.physicsBooster}>
            <CountdownCircleTimer
                key={duration} // using duration as key to force re-render after booster update
                isPlaying
                initialRemainingTime={remainingTime / 1000}
                duration={duration / 1000}
                colors="#FF6600"
                size={62}
                strokeWidth={5}
            >
                {({ remainingTime }) => {
                    return <img className={styles.physicsBoosterIcon} src={icon} alt="Physics Booster" />;
                }}
            </CountdownCircleTimer>
        </div>
    );
};

const BoosterArrow = () => {
    const noBoosterVisible = useSelector((state) => state.game.noBoosterVisible);
    const nextBoosterDirection = useSelector((state) => state.game.nextBoosterDirection);

    if (!noBoosterVisible) {
        return null;
    }

    return (
        <div className={styles.arrow} data-direction={nextBoosterDirection}>
            <img src={iconArrow} alt="Pointer towards next booster" />
        </div>
    );
};

const HUD = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const developmentMode = useSelector((state) => state.game.developmentMode);
    const cameraRotation = useSelector((state) => state.game.cameraRotation);
    const running = useSelector((state) => state.game.running);
    const dropped = useSelector((state) => state.game.dropped);
    const finished = useSelector((state) => state.game.finished);
    const endTime = useSelector((state) => state.game.endTime);

    const ladleActive = useSelector((state) => state.game.physicLadleActive);
    const ladleStart = useSelector((state) => state.game.startTime + state.game.physicLadleStart);
    const ladleDuration = useSelector((state) => state.game.physicLadleDuration);

    const isReady = useSelector((state) => state.game.ready);
    const isPositionCorrect = useSelector((state) => state.game.isPositionCorrect);
    const [countdown, setCountdown] = useState(COUNTDOWN_TIME);
    const [crosshairStyle, setCrosshairStyle] = useState({ left: 0, top: 0 });
    const history = useHistory();

    // check position 30 times per second
    useInterval(
        () => {
            const crossTop = `${rad2deg(cameraRotation.x) * -1 + 5}%`;
            const crossLeft = `${50 - rad2deg(cameraRotation.z)}%`;
            if (crosshairStyle.top !== crossTop || crosshairStyle.left !== crossLeft) {
                setCrosshairStyle({
                    top: crossTop,
                    left: crossLeft,
                });
            }

            if (
                rad2deg(cameraRotation.x) >= -40 ||
                rad2deg(cameraRotation.x) <= -50 ||
                rad2deg(cameraRotation.z) <= -5 ||
                rad2deg(cameraRotation.z) >= 5
            ) {
                if (isPositionCorrect) {
                    dispatch(setPositionCorrect(false));
                }
                return;
            }

            if (isPositionCorrect) {
                return;
            }
            // Start countdown if position is correct
            setCountdown(COUNTDOWN_TIME);
            dispatch(setPositionCorrect(true));
        },
        isReady ? 64 : null,
    );
    // countdown
    useInterval(
        () => {
            setCountdown(countdown - 1);
            if (countdown === 1) {
                dispatch(start());
            }
        },
        !running && isPositionCorrect ? 1000 : null,
    );

    // Game play progress if running
    useInterval(
        () => {
            dispatch(tick());
        },
        running ? gameInterval : null,
    );

    const gameFinishStartedRef = useRef(false);
    useEffect(() => {
        if (!running && endTime) {
            if (!finished) {
                if (gameFinishStartedRef.current === false) {
                    gameFinishStartedRef.current = true;
                    // Display the end of the game banner for 3 seconds
                    setTimeout(() => {
                        history.push('score');
                    }, 3000);
                }
            }
        }
    }, [dispatch, running, finished, endTime, developmentMode, history]);

    useEffect(() => {
        dispatch(prepareGame());
    }, [dispatch]);

    return (
        <>
            <div className={styles.Hud}>
                <div className={styles.container}>
                    <CurrentScore />
                    <RemainingTime />
                </div>

                {running && <Messages />}

                {isReady && (
                    <div className={styles.OrientationOverlay}>
                        <>
                            <h3>{t('game.hud.calibration')}</h3>

                            <div className={styles.box}>
                                <div
                                    className={cn(styles.target, {
                                        [styles.green]: isPositionCorrect,
                                    })}
                                ></div>
                                <div className={styles.current} style={crosshairStyle}></div>
                            </div>
                        </>
                    </div>
                )}
                {!running &&
                    !finished &&
                    endTime &&
                    (dropped ? (
                        <div className={styles.OrientationOverlay}>
                            <h3>{t('game.hud.egg.falled')}</h3>
                        </div>
                    ) : (
                        <div className={styles.OrientationOverlay}>
                            <h3>{t('game.hud.timeup')}</h3>
                        </div>
                    ))}

                {ladleActive && <PhysicsTimer start={ladleStart} duration={ladleDuration} icon={iconBoosterLadle} />}
                {running && <BoosterArrow />}
            </div>
        </>
    );
};

export { HUD };
