import { useState, useEffect, useMemo } from 'react';
import StatsImpl from 'stats.js';
import { useFrame } from 'react-three-fiber';
import { Clock } from 'three';
import { useSelector } from 'react-redux';

const FPSStats = ({ visible }) => {
    const [stats] = useState(() => new StatsImpl());
    const lowPerf = useSelector((state) => state.game.debug.lowPerf);

    useEffect(() => {
        if (!visible) {
            return;
        }

        stats.showPanel(0);

        document.body.appendChild(stats.dom);
        return () => document.body.removeChild(stats.dom);
    }, [visible, stats]);

    // Limit to 30fps in low perf mode
    const clock = useMemo(() => new Clock(), []);
    const targetInterval = useMemo(() => 1 / (lowPerf ? 30 : 60), [lowPerf]);
    let delta = 0;
    return useFrame(({ gl, scene, camera }) => {
        if (!gl || !gl.render) {
            return;
        }

        delta += clock.getDelta();

        if (delta > targetInterval) {
            stats.begin();
            gl.render(scene, camera);
            stats.end();
            delta = delta % targetInterval;
        }
    }, 1);
};

export { FPSStats };
