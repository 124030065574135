import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonPrimary from '../elements/ButtonPrimary';
import ButtonSecondary from '../elements/ButtonSecondary';

const PlayPermissions = ({ givePermissions }) => {
    const { t } = useTranslation();

    return (
        <div className="eggrun-play eggrun-play-permissions">
            <div className="eggrun-text-content">
                <h2>{t('play.permissions.title')}</h2>
                <p dangerouslySetInnerHTML={{ __html: t('play.permissions.html') }} />
            </div>
            <div className="eggrun-play-permissions-button">
                <ButtonPrimary linkText={t('play.permissions.button_continue')} handleClick={givePermissions} />
                <ButtonSecondary linkType="internal" linkUrl="/" linkText={t('play.permissions.button_back')} />
            </div>
        </div>
    );
};

export default PlayPermissions;
