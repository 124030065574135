import lerp from 'lerp';
import { minDistanceToHandleForNewBooster, worldSize } from '../config/game';

function rad2deg(radians) {
    return radians * (180 / Math.PI);
}

function deg2rad(degrees) {
    return degrees * (Math.PI / 180);
}

/**
 * Lerp angle implementation stolen from
 * https://godotengine.org/qa/5770/how-to-lerp-between-two-angles?show=24831#a5798
 *
 * It takes negative angles into account when lerping
 */
function positiveModulo(a, b) {
    if (a >= 0) {
        return a % b;
    }

    return b - (-a % b);
}

function normalizeAngle(rad) {
    return positiveModulo(rad + Math.PI, 2.0 * Math.PI) - Math.PI;
}

function lerpAngle(radA, radB, t) {
    if (Math.abs(radA - radB) >= Math.PI) {
        if (radA > radB) {
            radA = normalizeAngle(radA) - 2.0 * Math.PI;
        } else {
            radB = normalizeAngle(radB) - 2.0 * Math.PI;
        }
    }

    return lerp(radA, radB, t);
}

function mapPercentagePositionToWorld({ x, y }) {
    return {
        x: (x / 100) * worldSize - worldSize / 2,
        y: (y / 100) * worldSize - worldSize / 2,
    };
}

/**
 * @param {{x: number, y: number}} posA
 * @param {int[]} handlePos
 * @param {number} distance
 * @returns {boolean}
 */
function isWithinRange(posA, handlePos, distance) {
    return Math.abs(posA.x - handlePos[0]) < distance && Math.abs(posA.y - handlePos[1]) < distance;
}

/**
 * Very naive implementation to find points in line of sight.
 * _Brainfuck incoming, all axis and rotation starting points are different_
 *
 * Checks if the point is approximately in sight when viewing from [0,0] with an angle. (threejs system!)
 * X axis: from - (left) to + (right)
 * Y axis: from - (front) to + (back) … (in threejs it is the Z axis!)
 *
 * Rotation is counter clockwise (threejs system!):
 * View directly to front (-Y) is a 0° rotation.
 * View to the left (-X) is a 90° rotation.
 * View to the right(+X) is a -90° rotation.
 */
/**
 *
 * @param {{x: Number, y: Number}} positionA
 * @param {{x: Number, y: Number}} positionB
 * @returns {Number} Radians
 */
function angleBetweenPoints(positionA, positionB) {
    const normalizedPosition = {
        x: positionA.x - positionB.x,
        y: positionA.y - positionB.y,
    };

    /**
     * y * -1: because threejs has - on top
     * +90° because atan2 0° starts at positive x compared to threejs at -y
     */
    return Math.atan2(normalizedPosition.y * -1, normalizedPosition.x) - deg2rad(90);
}

function angleDeviation(a, b) {
    return normalizeAngle(normalizeAngle(a) - normalizeAngle(b));
}

/**
 * @param {{x: Number, y: Number}} position
 * @param {Number} angle Radians of camera rotation
 */
function isWithinSight(position, angle) {
    // Angle in both directions that are visible
    const visibleAngle = deg2rad(30);
    const deviation = angleDeviation(angle, angleBetweenPoints(position, { x: 0, y: 0 }));

    return Math.abs(deviation) <= visibleAngle;
}

function getRandomAvailablePosition(availablePositions, handlePosition) {
    const positions = availablePositions
        .map(mapPercentagePositionToWorld)
        .filter((pos) => !isWithinRange(pos, handlePosition, minDistanceToHandleForNewBooster));

    return positions[Math.floor(Math.random() * positions.length)];
}

export {
    rad2deg,
    deg2rad,
    lerpAngle,
    mapPercentagePositionToWorld,
    getRandomAvailablePosition,
    isWithinRange,
    isWithinSight,
    angleBetweenPoints,
    angleDeviation,
};
