import React from 'react';
import { useTranslation } from 'react-i18next';

import image from '../assets/rotate.svg';

const EggrunRotate = () => {
    const { t } = useTranslation();

    return (
        <div className="eggrun-rotate">
            <div className="eggrun-rotate-egg-top-left" />
            <div className="eggrun-rotate-egg-top-middle" />
            <div className="eggrun-rotate-egg-top-right" />
            <div className="eggrun-rotate-egg-bottom-left" />
            <div className="eggrun-rotate-egg-bottom-middle" />
            <div className="eggrun-rotate-egg-bottom-right" />
            <img className="eggrun-rotate-image" src={image} alt={t('main.rotate.image_alt')} />
        </div>
    );
};

export default EggrunRotate;
