import React, { createContext, memo, useEffect } from 'react';
import { useFBX, useTexture } from '@react-three/drei';
import { shuffleArray } from '../../utils/game-utils';
import boosterTexturePath1 from '../../assets/models/egg/TexturesVar/Egg_Strawberry_Almond/Strawberry_Almond.jpg';
import boosterTexturePath2 from '../../assets/models/egg/TexturesVar/Egg_Hazelnut_Cream/Hazelnut_Cream.jpg';
import boosterTexturePath3 from '../../assets/models/egg/TexturesVar/Egg_Praline&Nuts/Praline&Nuts.jpg';
import boosterTexturePath4 from '../../assets/models/egg/TexturesVar/Egg_Ristretto/Ristretto.jpg';
import boosterTexturePath5 from '../../assets/models/egg/TexturesVar/Egg_Rueblitorte/Rueblitorte.jpg';
import boosterTexturePath6 from '../../assets/models/egg/TexturesVar/Egg_Mocca/Mocca.jpg';
import boosterTexturePath7 from '../../assets/models/egg/TexturesVar/Egg_Hazelnut_Crunch/Hazelnut_Crunch.jpg';
import boosterTexturePath8 from '../../assets/models/egg/TexturesVar/Egg_Cocos/Cocos.jpg';
import boosterModelPath from '../../assets/models/egg/egg_animationSet.fbx';
import boosterNormalMapPath from '../../assets/models/egg/egg_NormalMap.png';
import boosterOrmMapPath from '../../assets/models/egg/egg_ORM.png';
import eggModelPath from '../../assets/models/egg/Egg.fbx';
import eggTexturePath from '../../assets/models/egg/TexturesVar/Egg_Lemon_Cheesecake/Lemon_Cheesecake.jpg';
import eggNormalMapPath from '../../assets/models/egg/egg_NormalMap.png';
import eggOrmMapPath from '../../assets/models/egg/egg_ORM.png';
import spoonModelPath from '../../assets/models/spoon/Spoon.fbx';
import spoonNormalMapPath from '../../assets/models/spoon/spoon_NormalMap.png';
import spoonOrmMapPath from '../../assets/models/spoon/spoon_ORM.png';
import mocaSpoonModelPath from '../../assets/models/MoccaSpoon/MoccaSpoon_80.fbx';
import mocaSpoonNormalMapPath from '../../assets/models/MoccaSpoon/MoccaSpoonNormalmap.png';
import mocaSpoonOrmMapPath from '../../assets/models/MoccaSpoon/MoccaSpoon_ORM.png';
import mocaSpoonTexturePath from '../../assets/models/MoccaSpoon/MoccaSpoon_BaseColor.png';
import ladleModelPath from '../../assets/models/Ladle/Ladle.fbx';
import ladleOrmMapPath from '../../assets/models/Ladle/Ladle_ORM.png';
import ladleTexturePath from '../../assets/models/Ladle/Ladle_BaseColor.png';
import forkModelPath from '../../assets/models/Fork/Fork.fbx';
import forkOrmMapPath from '../../assets/models/Fork/Fork_ORM.png';
import forkTexturePath from '../../assets/models/Fork/Fork_BaseColor.png';

import panTurnerModelPath from '../../assets/models/PanTurner/PanTurner.fbx';
import panTurnerOrmMapPath from '../../assets/models/PanTurner/PanTurner_ORM.png';
import panTurnerTexturePath from '../../assets/models/PanTurner/PanTurner_BaseColor.png';

const eggTextures = [
    boosterTexturePath1,
    boosterTexturePath2,
    boosterTexturePath3,
    boosterTexturePath4,
    boosterTexturePath5,
    boosterTexturePath6,
    boosterTexturePath7,
    boosterTexturePath8,
    eggTexturePath,
];
const shuffledEggTextures = [...shuffleArray(eggTextures)];

useFBX.preload(boosterModelPath);
useFBX.preload(eggModelPath);
useFBX.preload(spoonModelPath);
useFBX.preload(mocaSpoonModelPath);
useFBX.preload(ladleModelPath);
useFBX.preload(forkModelPath);
useFBX.preload(panTurnerModelPath);

useTexture.preload(boosterNormalMapPath);
useTexture.preload(eggNormalMapPath);
useTexture.preload(boosterOrmMapPath);
useTexture.preload(eggOrmMapPath);
useTexture.preload(boosterTexturePath1);
useTexture.preload(boosterTexturePath2);
useTexture.preload(boosterTexturePath3);
useTexture.preload(boosterTexturePath4);
useTexture.preload(boosterTexturePath5);
useTexture.preload(boosterTexturePath6);
useTexture.preload(boosterTexturePath7);
useTexture.preload(boosterTexturePath8);
useTexture.preload(eggTexturePath);
useTexture.preload(spoonNormalMapPath);
useTexture.preload(spoonOrmMapPath);
useTexture.preload(mocaSpoonNormalMapPath);
useTexture.preload(mocaSpoonOrmMapPath);
useTexture.preload(mocaSpoonTexturePath);
useTexture.preload(ladleOrmMapPath);
useTexture.preload(ladleTexturePath);
useTexture.preload(forkOrmMapPath);
useTexture.preload(forkTexturePath);
useTexture.preload(panTurnerOrmMapPath);
useTexture.preload(panTurnerTexturePath);

const AssetsContext = createContext({
    models: {
        booster: null,
        egg: null,
        spoon: null,
        mocaSpoon: null,
        ladle: null,
        fork: null,
        panTurner: null,
    },
    normalMaps: {
        booster: null,
        egg: null,
        spoon: null,
        mocaSpoon: null,
    },
    ormMaps: {
        booster: null,
        egg: null,
        spoon: null,
        mocaSpoon: null,
        ladle: null,
        fork: null,
        panTurner: null,
    },
    textures: {
        boosters: [],
        egg: null,
        mocaSpoon: null,
        ladle: null,
        fork: null,
        panTurner: null,
    },
});

export function disposeModel(model) {
    model.children.forEach((mesh) => {
        mesh.material.dispose();
        mesh.geometry.dispose();
    });
}

const AssetsContextProvider = memo((props) => {
    const assets = {
        models: {
            booster: useFBX(boosterModelPath),
            egg: useFBX(eggModelPath),
            spoon: useFBX(spoonModelPath),
            mocaSpoon: useFBX(mocaSpoonModelPath),
            ladle: useFBX(ladleModelPath),
            fork: useFBX(forkModelPath),
            panTurner: useFBX(panTurnerModelPath),
        },
        normalMaps: {
            booster: useTexture(boosterNormalMapPath),
            egg: useTexture(eggNormalMapPath),
            spoon: useTexture(spoonNormalMapPath),
            mocaSpoon: useTexture(mocaSpoonNormalMapPath),
        },
        ormMaps: {
            booster: useTexture(boosterOrmMapPath),
            egg: useTexture(eggOrmMapPath),
            spoon: useTexture(spoonOrmMapPath),
            mocaSpoon: useTexture(mocaSpoonOrmMapPath),
            ladle: useTexture(ladleOrmMapPath),
            fork: useTexture(forkOrmMapPath),
            panTurner: useTexture(panTurnerOrmMapPath),
        },
        textures: {
            boosters: [
                useTexture(shuffledEggTextures[0]),
                useTexture(shuffledEggTextures[1]),
                useTexture(shuffledEggTextures[2]),
                useTexture(shuffledEggTextures[3]),
                useTexture(shuffledEggTextures[4]),
                useTexture(shuffledEggTextures[5]),
                useTexture(shuffledEggTextures[6]),
                useTexture(shuffledEggTextures[7]),
            ],
            egg: useTexture(shuffledEggTextures[8]),
            mocaSpoon: useTexture(mocaSpoonTexturePath),
            ladle: useTexture(ladleTexturePath),
            fork: useTexture(forkTexturePath),
            panTurner: useTexture(panTurnerTexturePath),
        },
    };

    useEffect(() => {
        return () => {
            disposeModel(assets.models.booster);
            disposeModel(assets.models.egg);
            disposeModel(assets.models.spoon);
            disposeModel(assets.models.mocaSpoon);
            disposeModel(assets.models.ladle);
            disposeModel(assets.models.fork);
            disposeModel(assets.models.panTurner);

            assets.normalMaps.booster.dispose();
            assets.normalMaps.egg.dispose();
            assets.normalMaps.spoon.dispose();
            assets.normalMaps.mocaSpoon.dispose();

            assets.ormMaps.booster.dispose();
            assets.ormMaps.egg.dispose();
            assets.ormMaps.spoon.dispose();
            assets.ormMaps.mocaSpoon.dispose();
            assets.ormMaps.ladle.dispose();
            assets.ormMaps.fork.dispose();
            assets.ormMaps.panTurner.dispose();

            assets.textures.boosters.forEach((texture) => texture.dispose());
            assets.textures.egg.dispose();
            assets.textures.mocaSpoon.dispose();
            assets.textures.ladle.dispose();
            assets.textures.fork.dispose();
            assets.textures.panTurner.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AssetsContext.Provider value={assets}>{props.children}</AssetsContext.Provider>;
});

AssetsContextProvider.displayName = 'AssetsContextProvider';

export { AssetsContext, AssetsContextProvider };
