import React from 'react';
import { useTranslation } from 'react-i18next';

import { LinkCms } from '../features/elements';
import ButtonSecondary from '../elements/ButtonSecondary';

const PlayDeviceError = ({ denied }) => {
    const { t } = useTranslation();

    return (
        <div className="eggrun-play-device-error">
            <div className="eggrun-play-device-error-title">
                {denied ? t('play.device_error.title_denied_access') : t('play.device_error.title')}
            </div>
            {denied ? (
                <div className="eggrun-play-device-error-text">{t('play.device_error.text_denied_access')}</div>
            ) : (
                <div className="eggrun-play-device-error-text">{t('play.device_error.text')}</div>
            )}

            {denied && (
                <>
                    <div className="eggrun-play-device-error-text">
                        {t('play.device_error.camera')}: {denied.camera ? 'ok' : '×'}
                        {' / '}
                        {t('play.device_error.sensors')}: {denied.sensors ? 'ok' : '×'}
                        {' / '}
                        {t('play.device_error.device')}: {denied.device ? 'ok' : '×'}
                        {denied.errors && (
                            <>
                                <br />
                                <small>
                                    <em>{denied.errors.join(', ')}</em>
                                </small>
                            </>
                        )}
                    </div>
                    <div className="eggrun-play-device-error-text">
                        {t('play.device_error.text_give_access')}
                        <LinkCms
                            type="internal"
                            url="eggrun/rules#permissions"
                            className="eggrun-play-device-error-link"
                        >
                            {t('play.device_error.text_give_access_link')}
                        </LinkCms>
                    </div>
                </>
            )}

            <div className="eggrun-play-device-error-button">
                <ButtonSecondary linkType="internal" linkUrl="/" linkText={t('play.device_error.button')} />
            </div>
        </div>
    );
};

export default PlayDeviceError;
