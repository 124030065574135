import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFrame, useThree, extend } from 'react-three-fiber';
import { setCameraRotation } from '../../model/game';
import lerp from 'lerp';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls.js';
import { useDeviceMotion } from '../../utils/useDeviceMotion';
import { worldSize } from '../../config/game';

extend({ DeviceOrientationControls });

const movementSpeed = 0.1;
const accelerationRate = 0.12;
const deccelerationRate = 0.75;

const positionLimit = worldSize / 2;
const boxArgs = [1, 1, 1];

const DeviceOrientationMovementControls = (props) => {
    const { camera } = useThree();
    const controlsRef = useRef();
    const dispatch = useDispatch();
    const { resetDelta, isMoving } = useDeviceMotion();
    const running = useSelector((state) => state.game.running);

    /**
     *
     * @type {React.MutableRefObject<THREE.Object3D>}
     */
    const ghostCamera = useRef();

    const currentMovementSpeed = useRef(0);
    useFrame(({ gl, scene }) => {
        if (!gl || !gl.render) {
            return;
        }

        controlsRef.current?.update();
        //gl.render(scene, camera);

        dispatch(
            setCameraRotation({
                x: camera.rotation.x,
                y: camera.rotation.y,
                z: camera.rotation.z,
            }),
        );

        if (ghostCamera.current) {
            // rotate same as camera (same as device), so we can below use translate with de calculated motion from the device
            // without complex calculations

            // only copy y orientation on camera so translateX movement is always foreward (and not e.g downwards)
            ghostCamera.current.rotation.y = camera.rotation.y;

            if (isMoving.current && running) {
                // accelerate
                currentMovementSpeed.current = lerp(currentMovementSpeed.current, movementSpeed, accelerationRate);
            } else {
                // deccelerate
                currentMovementSpeed.current = lerp(currentMovementSpeed.current, 0, deccelerationRate);
            }

            if (currentMovementSpeed.current > 0) {
                ghostCamera.current.translateZ(-1 * currentMovementSpeed.current);
            }

            // limit position after translation (keep camera height, limit to world)
            ghostCamera.current.position.y = camera.position.y;
            ghostCamera.current.position.x = Math.max(
                Math.min(ghostCamera.current.position.x, positionLimit),
                -positionLimit,
            );
            ghostCamera.current.position.z = Math.max(
                Math.min(ghostCamera.current.position.z, positionLimit),
                -positionLimit,
            );

            // reset motion deltas
            resetDelta();

            camera.position.x = ghostCamera.current.position.x;
            camera.position.z = ghostCamera.current.position.z;
        }
    });

    return (
        <>
            <mesh ref={ghostCamera} visible={false}>
                <boxBufferGeometry args={boxArgs} />
                <meshStandardMaterial color={'hotpink'} />
            </mesh>

            <deviceOrientationControls ref={controlsRef} args={[camera]} {...props} />
        </>
    );
};

export { DeviceOrientationMovementControls };
