import { createSlice } from '@reduxjs/toolkit';

const errorCodesEndpoint = {};
errorCodesEndpoint['00'] = 'Crimson';
errorCodesEndpoint['01'] = 'Green';
errorCodesEndpoint['02'] = 'Red';
errorCodesEndpoint['03'] = 'Orange';
errorCodesEndpoint['04'] = 'Black';
errorCodesEndpoint['05'] = 'Gold';
errorCodesEndpoint['10'] = 'Brown';
errorCodesEndpoint['11'] = 'Yellow';
errorCodesEndpoint['12'] = 'Pink';
errorCodesEndpoint['13'] = 'Grey';
errorCodesEndpoint['14'] = 'Blue';
errorCodesEndpoint['15'] = 'Purple';
errorCodesEndpoint['16'] = 'White';
errorCodesEndpoint['17'] = 'Beige';
errorCodesEndpoint['18'] = 'Violet';
errorCodesEndpoint['19'] = 'Azure';

const errorCodesStatus = {};
errorCodesStatus['200'] = 'Rabbit';
errorCodesStatus['226'] = 'Egg';
errorCodesStatus['400'] = 'Grass';
errorCodesStatus['401'] = 'Basket';
errorCodesStatus['403'] = 'Meadow';
errorCodesStatus['404'] = 'Flower';
errorCodesStatus['409'] = 'Chicken';
errorCodesStatus['429'] = 'Tree';
errorCodesStatus['500'] = 'Spoon';
// Lamb

const modelEggrun = createSlice({
    name: 'eggrun',
    initialState: {
        sidebarIsOpen: false,
        scrollIsDisabled: true,
        errorPopup: {
            open: false,
            title: null,
            text: null,
            code: null,
        },
        weekNumberState: 'init', // 'init', 'loading', 'ready'
        weekNumberError: false,
        weekNumber: null,
        userState: 'init', // 'init', 'loading', 'ready'
        userError: false,
        userData: {},
        rankingState: 'init', // 'init', 'loading', 'ready'
        rankingError: false,
        rankingTop: [],
        rankingUser: [],
        rankingUserPosition: null,
    },
    reducers: {
        sidebarOpen: (state) => {
            state.sidebarIsOpen = true;
        },
        sidebarClose: (state) => {
            state.sidebarIsOpen = false;
        },
        scrollEnable: (state) => {
            state.scrollIsDisabled = false;
        },
        scrollDisable: (state) => {
            state.scrollIsDisabled = true;
        },
        errorPopupOpen: (state, action) => {
            const { title = null, text = null } = action.payload || {};

            state.errorPopup.open = title !== null && text !== null;
            state.errorPopup.title = title;
            state.errorPopup.text = text;
        },
        errorPopupOpenResponse: (state, action) => {
            const { status, endpoint } = action.payload;
            let endpointText = `${endpoint}`;
            endpointText =
                typeof errorCodesEndpoint[endpointText] !== 'undefined'
                    ? errorCodesEndpoint[endpointText]
                    : endpointText;
            let statusText = `${status}`;
            statusText =
                typeof errorCodesStatus[statusText] !== 'undefined' ? errorCodesStatus[statusText] : statusText;
            const code = `${endpointText} ${statusText}`;

            state.errorPopup.open = true;
            state.errorPopup.title = 'pages.eggrun.errors.general.title';
            state.errorPopup.text = 'pages.eggrun.errors.general.text';
            state.errorPopup.code = code;
        },
        errorPopupClose: (state, action) => {
            state.errorPopup.open = false;
            state.errorPopup.title = null;
            state.errorPopup.text = null;
        },
        weekNumberLoading: (state) => {
            state.weekNumberState = 'loading';
            state.weekNumberError = false;
        },
        weekNumberError: (state) => {
            state.weekNumberState = 'ready';
            state.weekNumberError = true;
        },
        weekNumberUpdate: (state, action) => {
            const { roundGroupIndex = null } = action.payload;
            state.weekNumberState = 'ready';
            state.weekNumberError = false;
            state.weekNumber = roundGroupIndex;
        },
        userLoading: (state) => {
            state.userState = 'loading';
            state.userError = false;
        },
        userError: (state) => {
            state.userState = 'ready';
            state.userError = true;
        },
        userUpdate: (state, action) => {
            state.userState = 'ready';
            state.userError = false;
            state.userData = action.payload;
        },
        rankingLoading: (state) => {
            state.rankingState = 'loading';
            state.rankingError = false;
        },
        rankingError: (state) => {
            state.grankingState = 'ready';
            state.grankingError = true;
        },
        rankingUpdate: (state, action) => {
            state.rankingState = 'ready';
            state.rankingError = false;
            state.rankingTop = action.payload.top;
            state.rankingUser = action.payload.ranking;
            state.rankingUserPosition = +action.payload.userPosition;
        },
    },
});

export default modelEggrun;

export const eggrunSidebarOpen = () => (dispatch) => {
    dispatch(modelEggrun.actions.sidebarOpen());
};

export const eggrunSidebarClose = () => (dispatch) => {
    dispatch(modelEggrun.actions.sidebarClose());
};

export const eggrunScrollEnable = () => (dispatch) => {
    dispatch(modelEggrun.actions.scrollEnable());
};

export const eggrunScrollDisable = () => (dispatch) => {
    dispatch(modelEggrun.actions.scrollDisable());
};

export const weekNumberLoad = () => async (dispatch, getState) => {
    dispatch(modelEggrun.actions.weekNumberLoading());
    const data = 1;
    dispatch(modelEggrun.actions.weekNumberUpdate(data));
};

export const eggrunUpdateUserStatus = (redirectOnError = true) => async (dispatch, getState) => {};

export const eggrunUpdateRanking = () => async (dispatch, getState) => {};
