/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';

const LinkCms = React.forwardRef(({ type, url, className = '', style = {}, children, internalOnClick = null }, ref) => {
    if (type === 'internal' && url) {
        const handleInternalOnClick = () => {
            if (internalOnClick) {
                internalOnClick();
            }
            window.scrollTo(0, 0);
        };

        return (
            <Link to={url} className={className} style={style} onClick={handleInternalOnClick} ref={ref}>
                {children}
            </Link>
        );
    }
    if (type === 'external' && url) {
        return (
            <a
                href={url}
                className={className}
                style={style}
                target="_blank"
                rel="noopener noreferrer"
                onClick={internalOnClick}
                ref={ref}
            >
                {children}
            </a>
        );
    }
    if (internalOnClick !== null) {
        return (
            <span className={className} style={style} ref={ref} onClick={internalOnClick}>
                {children}
            </span>
        );
    }
    return (
        <span className={className} style={{ cursor: 'default', ...style }} ref={ref}>
            {children}
        </span>
    );
});

export default LinkCms;
