import React, { Fragment } from 'react';
import cx from 'classnames';

const EggrunWave = ({ type = 'top' }) => {
    return (
        <div className={cx('eggrun-wave', `eggrun-wave--${type}`)}>
            {type === 'top' && (
                <Fragment>
                    <div className="eggrun-wave-egg-1" />
                    <div className="eggrun-wave-egg-2" />
                    <div className="eggrun-wave-egg-3" />
                </Fragment>
            )}
            <div className="eggrun-wave-shape" />
        </div>
    );
};

export default EggrunWave;
