import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import cx from 'classnames';

import { routesUrl } from './routes';
// import { weekGetCurrent } from 'config/weeks';
import { getUserMediaConstraints } from './utils/game-utils';

import EggrunDesktop from './main/EggrunDesktop';
import EggrunRotate from './main/EggrunRotate';
import EggrunLoading from './main/EggrunLoading';
import PlayDeviceError from './main/PlayDeviceError';
import PlayPermissions from './main/PlayPermissions';

import EggrunOverviewPlay from './views/EggrunOverviewPlay';
import EggrunScore from './views/EggrunScore';
import { OsternGame } from './ostern-game';

const checkSensorPermission = () => {
    return new Promise((resolve, reject) => {
        if (
            window.DeviceMotionEvent &&
            typeof window.DeviceMotionEvent.requestPermission === 'function' &&
            window.DeviceOrientationEvent &&
            typeof window.DeviceOrientationEvent.requestPermission === 'function'
        ) {
            // Newer iOS devices
            window.DeviceOrientationEvent.requestPermission()
                .then((response) => {
                    if (response === 'granted') {
                        window.DeviceMotionEvent.requestPermission()
                            .then((response) => {
                                if (response === 'granted') {
                                    resolve();
                                } else {
                                    reject(`Motion: access denied (${response})`);
                                }
                            })
                            .catch((e) => {
                                reject(`Motion: ${e}`);
                            });
                    } else {
                        reject(`Orientation: access denied (${response})`);
                    }
                })
                .catch((e) => {
                    reject(`Orientation: ${e}`);
                });
        } else if (navigator.permissions && typeof navigator.permissions.query === 'function') {
            // Devices supporting the navigor.permissions API (Android devices)
            Promise.all([
                navigator.permissions.query({ name: 'accelerometer' }),
                navigator.permissions.query({ name: 'gyroscope' }),
            ])
                .then((results) => {
                    if (results.every((result) => result.state === 'granted')) {
                        resolve();
                        return;
                    }

                    reject(`Required sensor permissions denied (${results.map((result) => result.state).join(', ')})`);
                })
                .catch((e) => {
                    reject(`Sensors: ${e}`);
                });
        } else {
            // Fallback to granted for devices not supporting any method to check if permission was granted
            resolve();
        }
    });
};

const checkCameraPermission = () => {
    return navigator.mediaDevices.getUserMedia(getUserMediaConstraints()).then((stream) => {
        // same clean up logic as patched react-webcam
        stream.getTracks().forEach(function (track) {
            stream.removeTrack(track);
            track.stop();
        });
    });
};

const Eggrun = () => {
    const locale = '';

    useEffect(() => {
        document.body.classList.add('body-fixed');
        return () => document.body.classList.remove('body-fixed');
    }, []);

    const deviceAllowed = window.matchMedia('(max-width: 500px)').matches;
    const [permissions, setPermissions] = useState(false);
    const [denied, setDenied] = useState(false);

    /*const resetPermissions = useCallback(() => {
        setPermissions(false);
    }, []);*/

    const givePermissions = useCallback(async () => {
        const permissions = {
            device: deviceAllowed,
            camera: null,
            sensors: null,
            errors: [],
        };

        // sensors
        try {
            await checkSensorPermission();
            permissions.sensors = true;
        } catch (e) {
            console.error(e);
            permissions.sensors = false;
            permissions.errors.push(e);
        }

        // camera
        try {
            await checkCameraPermission();
            permissions.camera = true;
        } catch (e) {
            console.error(e);
            permissions.camera = false;
            permissions.errors.push(e);
        }

        if (permissions.camera && permissions.sensors) {
            // all permissions ok
            setPermissions(true);
        } else {
            setDenied(permissions);
        }
    }, [deviceAllowed]);

    return (
        <Fragment>
            <EggrunRotate />
            <div className="container-eggrun-bg">
                <EggrunDesktop />
                <div className={cx('container-eggrun', { 'container-eggrun--fixed': false })}>
                    <EggrunLoading />
                    <Switch>
                        <Route exact path="/">
                            <EggrunOverviewPlay />
                        </Route>
                        <Route exact path="/play">
                            {(!deviceAllowed || denied) && <PlayDeviceError denied={denied} />}
                            {deviceAllowed && !denied && !permissions && (
                                <PlayPermissions givePermissions={givePermissions} />
                            )}
                            {deviceAllowed && !denied && permissions && <OsternGame />}
                        </Route>
                        <Route exact path="/score">
                            <EggrunScore />
                        </Route>
                        <Route>
                            <Redirect to={routesUrl(locale, '/')} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Fragment>
    );
};

export default Eggrun;
