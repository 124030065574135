import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAnimation, clearEventAnimation } from '../../model/game';

import styles from './animation.module.scss';
import { eventType } from '../../config/game';

/**
 * Sprite was generated with https://codeshack.io/images-sprite-sheet-generator/. It just takes array of frames and generates such sprite.
 *
 * It is even easier on console with imagemagick
 *
 *      convert +append *.png ../sprite.png
 */

const BoosterAnimations = () => {
    const dispatch = useDispatch();
    const animation = useSelector((state) => state.game.animation);
    const duration = 2700;
    const [playClass, setPlayClass] = useState(null);

    // start or stop animation
    useEffect(() => {
        if (!animation) {
            setPlayClass(styles.stopAnimation);
            return;
        }

        setPlayClass(styles.playAnimation);
    }, [animation]);

    // clear animation state after animation played
    useEffect(() => {
        if (!animation) {
            return;
        }

        const timer = setTimeout(() => {
            dispatch(clearAnimation());
        }, duration);
        return () => clearTimeout(timer);
    }, [animation, dispatch]);

    return (
        <>
            <div className={styles.animationContainer} data-name="2x">
                <div
                    className={[
                        styles.animation,
                        styles.boosterAnimation,
                        styles.boosterAnimation2x,
                        animation === '2x' ? playClass : styles.stopAnimation,
                    ].join(' ')}
                ></div>
            </div>
            <div className={styles.animationContainer} data-name="3x">
                <div
                    className={[
                        styles.animation,
                        styles.boosterAnimation,
                        styles.boosterAnimation3x,
                        animation === '3x' ? playClass : styles.stopAnimation,
                    ].join(' ')}
                ></div>
            </div>
            <div className={styles.animationContainer} data-name="4x">
                <div
                    className={[
                        styles.animation,
                        styles.boosterAnimation,
                        styles.boosterAnimation4x,
                        animation === '4x' ? playClass : styles.stopAnimation,
                    ].join(' ')}
                ></div>
            </div>
            <div className={styles.animationContainer} data-name="100">
                <div
                    className={[
                        styles.animation,
                        styles.boosterAnimation,
                        styles.boosterAnimation100p,
                        animation === '100' ? playClass : styles.stopAnimation,
                    ].join(' ')}
                ></div>
            </div>
            <div className={styles.animationContainer} data-name="500">
                <div
                    className={[
                        styles.animation,
                        styles.boosterAnimation,
                        styles.boosterAnimation500p,
                        animation === '500' ? playClass : styles.stopAnimation,
                    ].join(' ')}
                ></div>
            </div>
        </>
    );
};

const CountdownAnimation = () => {
    const [playClass, setPlayClass] = useState(null);
    const countdownAnimation = useSelector((state) => state.game.countdownAnimation);

    // start or stop animation
    useEffect(() => {
        if (!countdownAnimation) {
            setPlayClass(styles.stopAnimation);
            return;
        }

        setPlayClass(styles.playAnimation);
    }, [countdownAnimation]);

    // timeout to clear is not needed as the store takes care of clearing it

    return (
        <div className={styles.animationContainer} data-name="countdown">
            <div className={[styles.animation, styles.countdownAnimation, playClass].join(' ')}></div>
        </div>
    );
};

/**
 * Event animations (wind, obstruct) recurring during a game.
 * Only mount this component if they are needed
 */
const EventAnimation = () => {
    const dispatch = useDispatch();

    // get theme config
    const themeType = useSelector((state) => state.game.theme.type);
    const typeClass = useMemo(() => {
        switch (themeType) {
            case eventType.wind:
                return styles.windAnimation;
            case eventType.obstruct:
                return styles.obstructAnimation;
            default:
                console.error('<EventAnimation /> must only be mounted when event type is wind or obstruct!');
                return null;
        }
    }, [themeType]);

    const eventAnimation = useSelector((state) => state.game.eventAnimation);
    const duration = 5000; // could be replaced with a transitionend event handler
    const direction = useMemo(() => {
        return eventAnimation === 'wind-right' ? 'right' : 'left';
    }, [eventAnimation]);
    const [playClass, setPlayClass] = useState(null);

    // start or stop animation
    useEffect(() => {
        if (!eventAnimation) {
            setPlayClass(styles.stopAnimation);
            return;
        }

        setPlayClass(styles.playAnimation);
    }, [eventAnimation]);

    // clear animation state after animation played
    useEffect(() => {
        if (!eventAnimation) {
            return;
        }

        const timer = setTimeout(() => {
            dispatch(clearEventAnimation());
        }, duration);
        return () => clearTimeout(timer);
    }, [eventAnimation, dispatch]);

    return (
        <div className={styles.animationContainer} data-name="event">
            <div className={[styles.animation, typeClass, playClass].join(' ')} data-direction={direction}></div>
        </div>
    );
};

export { BoosterAnimations, CountdownAnimation, EventAnimation };
