import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import EggrunWave from '../parts/EggrunWave';
import EggrunFooter from '../parts/EggrunFooter';
import EggrunKeyvisual from '../parts/EggrunKeyvisual';
import ButtonPrimary from '../elements/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import styles from '../ostern-game.module.scss';

const EggrunScore = () => {
    const { t } = useTranslation();
    const points = useSelector((state) => state.game.points);

    // TODO redirect to / if game was not started (reloaded page on score page)

    return (
        <Fragment>
            <div className="eggrun-view">
                <div className="eggrun-view-rose">
                    <div className="eggrun-overview-keyvisual">
                        <EggrunKeyvisual small />
                    </div>
                    <div className="eggrun-points-score">
                        <div className="eggrun-points-score-number">{points}</div>
                        <div className="eggrun-points-score-label">{t('challenge_start.score_note')}</div>
                    </div>
                </div>
                <EggrunWave type="top" />
                <div className="eggrun-view-green">
                    <div className={styles.controls}>
                        <div className={`eggrun-text-content-button ${styles.play_button}`}>
                            <ButtonPrimary linkType="internal" linkUrl="/" linkText={'Nochmals spielen'} />
                        </div>
                    </div>
                </div>
                <EggrunFooter />
            </div>
        </Fragment>
    );
};

export default EggrunScore;
