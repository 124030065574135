import { useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Handle } from '../Handle';
import { Egg } from '../Egg';
import { PlayerContextProvider } from './PlayerContext';

const Player = () => {
    const running = useSelector((state) => state.game.running);
    // use to display spoon before egg
    const isPositionCorrect = useSelector((state) => state.game.isPositionCorrect);

    const alwaysShowHandle = true;

    const [shouldReinitializeEgg, setShouldReinitializeEgg] = useState(false);
    const [hideEgg, setHideEgg] = useState(false);

    const reninitializeEgg = useCallback(() => {
        setShouldReinitializeEgg(true);
        setHideEgg(false);
    }, []);

    useEffect(() => {
        setShouldReinitializeEgg(true);
    }, [running]);

    const onHandleMounted = useCallback(() => {
        if (running) {
            reninitializeEgg();
        }
    }, [reninitializeEgg, running]);

    const onHandleUnmounted = useCallback(() => {
        if (running) {
            setHideEgg(true);
        }
    }, [running]);

    const onEggReinitialized = useCallback(() => {
        setShouldReinitializeEgg(false);
    }, []);

    const content = useMemo(
        () => (
            <>
                <Egg
                    visible={!hideEgg && running}
                    reinitialize={shouldReinitializeEgg}
                    onReinitialized={onEggReinitialized}
                />
                <Handle
                    visible={alwaysShowHandle || running || isPositionCorrect}
                    onMount={onHandleMounted}
                    onUnmount={onHandleUnmounted}
                />
            </>
        ),
        [
            hideEgg,
            alwaysShowHandle,
            running,
            isPositionCorrect,
            shouldReinitializeEgg,
            onEggReinitialized,
            onHandleMounted,
            onHandleUnmounted,
        ],
    );

    return <PlayerContextProvider>{content}</PlayerContextProvider>;
};

export { Player };
