import React from 'react';
import { useDispatch } from 'react-redux';
import { usePlane } from '@react-three/cannon';
import { stop } from '../../model/game';

const Ground = () => {
    const dispatch = useDispatch();
    // When the ground was hit we reset the game ...
    const [ref] = usePlane(() => ({
        type: 'Static',
        rotation: [-Math.PI / 2, 0, 0],
        position: [0, -20, 0],
        onCollide: () => {
            dispatch(stop(true));
        },
    }));
    return <mesh ref={ref} />;
};

export { Ground };
