import React from 'react';
import { LinkCms } from '../features/elements';

const ButtonSecondary = ({ linkType = null, linkUrl = null, linkText, handleClick = null }) => {
    return (
        <LinkCms
            type={linkType}
            url={linkUrl}
            className="eggrun-button eggrun-button--secondary"
            internalOnClick={handleClick}
        >
            {linkText}
        </LinkCms>
    );
};

export default ButtonSecondary;
