import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './locales/de.json';
// import fr from './locales/fr.json';
// import it from './locales/it.json';

i18n.use(initReactI18next).init({
    resources: {
        de: { translation: de },
        // fr: { translation: fr },
        // it: { translation: it },
    },
    lng: 'de',
    fallbackLng: 'de',
    keySeparator: '.',
    debug: process.env.NODE_ENV === 'development',
});
