// game interval in ms
export const gameInterval = 100;
export const gameDuration = 120 * 1000; // two minutes
export const numberOfVisibleBoosters = 3;
export const timeToNotifyUserToCollectBoosters = 20 * 1000; // 20 seconds
/**
 * The player can move within a ~3x3 meter world.
 * This config defineds the 3d size of this world.
 */
export const worldSize = 35;
export const minDistanceToHandleForNewBooster = 10; // in world size units

export const models = {
    spoon: 'spoon',
    moccaSpoon: 'moccaSpoon',
    fork: 'fork',
    ladle: 'ladle',
    panTurner: 'panTurner',
};

/**
 * Each stage has a fixed duration 1/3 of game.
 * Each 100ms a defined amount of points are earned.
 * weeklyModifier, currently not used anywhere
 * sensitivityModifier, only used for wind strength
 */
export const stages = [
    {
        duration: gameDuration / 3,
        points: 1, // points per interval
        sensitivityModifier: 1,
    },
    {
        duration: gameDuration / 3,
        points: 2, // points per interval
        sensitivityModifier: 1.5,
    },
    {
        duration: gameDuration / 3,
        points: 3, // points per interval
        sensitivityModifier: 2,
    },
];

export const boosters = [
    // point modifier
    {
        name: '2x',
        duration: 5000,
        amountPerRound: 10,
        effect: {
            type: 'pointModifier',
            value: 2,
        },
    },
    {
        name: '3x',
        duration: 5000,
        amountPerRound: 6,
        effect: {
            type: 'pointModifier',
            value: 3,
        },
    },
    {
        name: '4x',
        duration: 5000,
        amountPerRound: 4,
        effect: {
            type: 'pointModifier',
            value: 4,
        },
    },

    // extra points
    {
        name: '100',
        amountPerRound: 15,
        effect: {
            type: 'addPoints',
            value: 100,
        },
    },
    {
        name: '500',
        amountPerRound: 8,
        effect: {
            type: 'addPoints',
            value: 500,
        },
    },

    // physics
    {
        name: 'ladle',
        duration: 5000,
        amountPerRound: 4,
        effect: {
            type: 'physicLadle',
        },
    },
];

/**
 * Booster positions are defined by a x&y percentage within a defined playable area.
 */

const boosterPositionsPercentageStep = 33;
const offsetPosition = (position) => {
    const offset = position + Math.round(Math.random() * 10);

    return Math.max(0, Math.min(100, offset));
};

let id = 0;
export const boosterPositions = [...Array(Math.round(100 / boosterPositionsPercentageStep) + 1)].flatMap(
    (el, indexX) => {
        return [...Array(Math.round(100 / boosterPositionsPercentageStep) + 1)]
            .flatMap((el, indexY) => {
                const x = offsetPosition(indexX * boosterPositionsPercentageStep);
                const y = offsetPosition(indexY * boosterPositionsPercentageStep);

                return {
                    id: id++,
                    x,
                    y,
                };
            })
            .filter((v) => v);
    },
);

export const eventType = {
    wind: 'wind',
    obstruct: 'obstruct',
    tool: 'tool',
    invert: 'invert',
};

export const weeklyThemes = [
    {
        name: 'Week 1 (Wind)',
        weeklyModifier: 1,
        type: eventType.wind,
        amount: 12,
        duration: 5000,
        options: ['left', 'right'],
    },
    {
        name: 'Week 2 (Sun/Fog)',
        weeklyModifier: 2,
        type: eventType.obstruct,
        amount: 12,
        duration: 5000,
    },
    {
        name: 'Week 3 (Spoons)',
        weeklyModifier: 3,
        type: eventType.tool,
        amount: 5, // 5 because the first one is fixed spoon
        duration: 20000,
        options: [models.moccaSpoon, models.fork, models.panTurner], // models available to change to
        forceChangingOption: true, // prevent same option for next event (must alternate)
    },
    {
        name: 'Week 4 (Inverted)',
        weeklyModifier: 4,
        type: eventType.invert,
        amount: 12,
        duration: 5000,
    },
];
