import React, { Fragment, useCallback, useEffect } from 'react';
import EggrunWave from '../parts/EggrunWave';
import EggrunFooter from '../parts/EggrunFooter';
import EggrunKeyvisual from '../parts/EggrunKeyvisual';
import { useDispatch, useSelector } from 'react-redux';
import { changeWeekMode, gameFinish } from '../model/game';
import ButtonPrimary from '../elements/ButtonPrimary';
import { useTranslation } from 'react-i18next';
import styles from '../ostern-game.module.scss';

const EggrunOverviewPlay = () => {
    const weekIndex = useSelector((state) => state.game.weekIndex);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const toggleWeek = useCallback(
        (key) => {
            dispatch(changeWeekMode(key));
        },
        [dispatch],
    );

    useEffect(() => {
        dispatch(gameFinish());
    }, [dispatch]);

    return (
        <Fragment>
            <div className="eggrun-view">
                <div className="eggrun-view-rose">
                    <div className="eggrun-overview-keyvisual">
                        <EggrunKeyvisual small />
                    </div>
                </div>
                <EggrunWave type="top" />
                <div className="eggrun-view-green">
                    <div className={styles.controls}>
                        <p>Wählen Sie das Level:</p>

                        <div>
                            <button
                                type="button"
                                onClick={() => toggleWeek(0)}
                                className={weekIndex !== 0 ? styles.btnInactive : ''}
                            >
                                Wind
                            </button>
                            <button
                                type="button"
                                onClick={() => toggleWeek(1)}
                                className={weekIndex !== 1 ? styles.btnInactive : ''}
                            >
                                Sonne
                            </button>
                            <button
                                type="button"
                                onClick={() => toggleWeek(2)}
                                className={weekIndex !== 2 ? styles.btnInactive : ''}
                            >
                                Löffel
                            </button>
                            <button
                                type="button"
                                onClick={() => toggleWeek(3)}
                                className={weekIndex !== 3 ? styles.btnInactive : ''}
                            >
                                Verkehrt
                            </button>
                        </div>
                        <div className={`eggrun-text-content-button ${styles.play_button}`}>
                            <ButtonPrimary linkType="internal" linkUrl="play" linkText={t('challenge.table.play')} />
                        </div>
                    </div>
                </div>
                <EggrunFooter />
            </div>
        </Fragment>
    );
};

export default EggrunOverviewPlay;
