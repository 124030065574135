import React, { useEffect, useMemo, useContext } from 'react';
import { MeshStandardMaterial } from 'three';
import { deg2rad } from '../../utils/geometry';
import { Tool } from './tool';
import { AssetsContext, disposeModel } from '../Gameplay/AssetsContext';

function createShapes() {
    const boxMaterial = {
        friction: 1,
        restitution: 0,
    };

    return [
        {
            // plate
            type: 'Box',
            args: [4, 0.25, 3],
            position: [0, -4.5, 0.5],
            material: boxMaterial,
        },
        {
            // bottom (near player)
            type: 'Box',
            args: [4, 0.25, 2],
            position: [0, -3.9, 2.5],
            rotation: [deg2rad(-40), 0, 0],
            material: boxMaterial,
        },
        {
            // top
            type: 'Box',
            args: [4, 0.25, 3],
            position: [0, -3.7, -1],
            rotation: [deg2rad(65), 0, 0],
            material: boxMaterial,
        },
        {
            // right
            type: 'Box',
            args: [2, 0.25, 3],
            position: [2, -3.9, 0.5],
            rotation: [0, 0, deg2rad(35)],
            material: boxMaterial,
        },
        {
            // left
            type: 'Box',
            args: [2, 0.25, 3],
            position: [-2, -3.9, 0.5],
            rotation: [0, 0, deg2rad(-35)],
            material: boxMaterial,
        },
    ];
}

const Ladle = (props) => {
    const {
        models: { ladle: originalModel },
        ormMaps: { ladle: ormMap },
        textures: { ladle: texture },
    } = useContext(AssetsContext);

    const model = useMemo(() => originalModel.clone(true), [originalModel]);

    // Prepare and assign material
    useEffect(() => {
        /**
         * @type {Object3D}
         */
        let mesh = null;
        model.traverse((node) => {
            if (node.isMesh) {
                mesh = node;
            }
        });

        mesh.position.set(mesh.position.x, -1.5, -0.6); // Move center to 0,0

        mesh.material = new MeshStandardMaterial({
            color: mesh.material.color,
            map: texture,
            metalness: 1, // required to make metalnessMap working
            envMapIntensity: 0.5,

            //aoMap: ormMap, // if set material is way too dark
            roughnessMap: ormMap,
            metalnessMap: ormMap,
        });
    }, [model, texture, ormMap]);

    const shapes = useMemo(() => createShapes(), []);

    useEffect(() => {
        const currentModel = model;
        return () => {
            disposeModel(currentModel);
        };
    }, [model]);

    return <Tool {...props} shapes={shapes} model={model} />;
};

export { Ladle };
