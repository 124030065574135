const localesCodes = [
    {
        code: 'de',
        name: 'Deutsch',
    },
    {
        code: 'fr',
        name: 'Français',
    },
    {
        code: 'it',
        name: 'Italiano',
    },
];

const routes = {
    home: { _main: { i18n: { de: null, fr: null, it: null } } },
    play: { _main: { i18n: { de: 'play', fr: 'play', it: 'play' } } },
    eggrun: {
        _main: {
            i18n: {
                de: 'der-grosse-migros-eierlauf',
                fr: 'la-grande-course-aux-oeufs-migros',
                it: 'la-grande-corsa-delle-uova-migros',
            },
        },
        rules: { i18n: { de: 'spielregeln', fr: 'pour-jouer', it: 'come-si-fa' } },
        rewards: { i18n: { de: 'preise', fr: 'prix', it: 'premi' } },
        ranking: { i18n: { de: 'rangliste', fr: 'classement', it: 'classifica' } },
        terms: { i18n: { de: 'teilnahmebedingungen', fr: 'conditions-participations', it: 'condizioni' } },
        challenge: { i18n: { de: 'duell', fr: 'duel', it: 'duelli' } },
        'challenge-won': { i18n: { de: 'duell-gewonnen', fr: 'duel-gagnde', it: 'duelli-vinto' } },
        'challenge-lost': { i18n: { de: 'duell-verloren', fr: 'duel-perdu', it: 'duelli-perso' } },
        'challenge-start': { i18n: { de: 'duell-start', fr: 'duel-depart', it: 'duelli-lancio' } },
        'challenge-join': { i18n: { de: 'duell-annahme', fr: 'duel-accepter', it: 'duelli-accetta' } },
        debug: { i18n: { de: 'debug', fr: 'debug', it: 'debug' } },
    },
};

export const routesPath = (locale, page = null, subpage = null, paramName = null) => {
    let path = `/:locale/`;
    if (page !== null && page !== '') {
        if (routes[page]._main.i18n[locale] !== null) {
            path += `${routes[page]._main.i18n[locale]}/`;
        }
        if (subpage !== null && subpage !== '') {
            path += `${routes[page][subpage].i18n[locale]}/`;
        }
        if (paramName !== null && paramName !== '') {
            path += `:${paramName}/`;
        }
    }

    return path;
};

export const routesUrl = (locale, page = null, subpage = null, param = null, hash = null) => {
    let url = `/${locale}/`;
    if (page !== null && page !== '') {
        if (
            typeof routes[page] === 'undefined' ||
            typeof routes[page]._main === 'undefined' ||
            typeof routes[page]._main.i18n === 'undefined' ||
            typeof routes[page]._main.i18n[locale] === 'undefined'
        ) {
            return url;
        }
        if (routes[page]._main.i18n[locale] !== null) {
            url += `${routes[page]._main.i18n[locale]}/`;
        }
        if (subpage !== null && subpage !== '') {
            if (
                typeof routes[page][subpage] === 'undefined' ||
                typeof routes[page][subpage].i18n === 'undefined' ||
                typeof routes[page][subpage].i18n[locale] === 'undefined'
            ) {
                return url;
            }
            url += `${routes[page][subpage].i18n[locale]}/`;
        }
        if (param !== null && param !== '') {
            url += `${param}/`;
        }
        if (hash !== null && hash !== '') {
            url += `#${hash}`;
        }
    }

    return url;
};

export const routesUrlAbsolute = (locale, page = null, subpage = null, param = null) => {
    return window.__env.siteBaseUrl + routesUrl(locale, page, subpage, param);
};

export const routesFindPage = (locale, localePage) => {
    return Object.keys(routes).find((routeKey) => routes[routeKey]._main.i18n[locale] === localePage);
};

export const routesFindSubpage = (locale, page, localeSubpage) => {
    return Object.keys(routes[page]).find((routeKey) => routes[page][routeKey].i18n[locale] === localeSubpage);
};

export const routesCurrentPage = (pathname) => {
    const locale = routesLocaleFromPathname(pathname);
    const pathnameParts = pathname.split('/');
    if (pathnameParts.length < 3 || locale === null || !pathnameParts[2]) {
        return null;
    }
    return routesFindPage(locale, pathnameParts[2]);
};

export const routesCurrentSubpage = (page, pathname) => {
    const locale = routesLocaleFromPathname(pathname);
    const pathnameParts = pathname.split('/');
    if (pathnameParts.length < 4 || !page || locale === null || !pathnameParts[3]) {
        return null;
    }
    return routesFindSubpage(locale, page, pathnameParts[3]);
};

export const routesLocaleFromPathname = (pathname) => {
    const pathnameParts = pathname.split('/');
    if (pathnameParts.length < 2 || !pathnameParts[1]) {
        return null;
    }
    const locale = pathnameParts[1];
    if (!localesCodes.includes(locale)) {
        return null;
    }

    return locale;
};
