/**
 * Generate a random integer between the min and max value
 *
 * @param {Number} min
 * @param {Number} max
 * @returns {Number}
 */
function generateTime(min, max) {
    return Math.round(min + Math.random() * (max - min));
}

/**
 * Each week has a different theme (wind, obstruction of sight, switch tools, inverted)
 * This method generates a list of events at the beginning of the game.
 * The game loop checks and dispatches the events.
 *
 * The events are evenly but randomly distributed over the game duration.
 */
function generateThemeEvents(theme, totalDuration) {
    const events = [];
    // The time window is the desired distribution of
    const timeWindow = (totalDuration - theme.duration) / theme.amount;

    while (events.length < theme.amount) {
        const eventIndex = events.length;
        const minStartTime = theme.duration + timeWindow * eventIndex;
        const maxStartTime = timeWindow + timeWindow * eventIndex;

        // if theme has options pick a random option per event
        const supplement = {};
        if (theme.options) {
            let available = theme.options;
            // The theme can force the options to alternate (not the same tool twice)
            if (theme.forceChangingOption && eventIndex !== 0) {
                // remove the previously selected option from the list
                available = theme.options.filter((option) => option !== events[eventIndex - 1].option);
            }

            supplement.option = shuffleArray(available)[0];
        }

        events.push({
            type: theme.type,
            at: generateTime(minStartTime, maxStartTime),
            duration: theme.duration,
            ...supplement,
        });
    }

    return events;
}

/**
 * Return a shuffled copy of the array
 *
 * @param {Array} arr
 * @returns {Array}
 */
function shuffleArray(arr) {
    return arr
        .map((a) => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map((a) => a[1]);
}

/**
 * S20+ (SM-G986B) and S20 Ultra (SM-G988B) return a back facing camera that not works at all (NotReadableError)
 * Adding a width constraints helps selecting a different device which works
 */
const isCrappySamsungDevice = () => {
    if (!navigator.userAgent || !navigator.userAgent.match) {
        return false;
    }

    // S20+
    if (navigator.userAgent.match(/SM-G986/i)) {
        return true;
    }

    // S20 Ultra
    if (navigator.userAgent.match(/SM-G988/i)) {
        return true;
    }

    return false;
};

const getUserMediaConstraints = () => {
    const constraints = { audio: false, video: { facingMode: 'environment' } };

    // Workaround for S20 Ultra and S20+
    if (isCrappySamsungDevice()) {
        constraints.video.width = { ideal: 720 };
    }

    return constraints;
};

export { generateTime, shuffleArray, generateThemeEvents, getUserMediaConstraints };
