import { useEffect, useRef, useCallback } from 'react';
import { DeviceMotion } from './DeviceMotion';
import useInterval from '@use-it/interval';

function useDeviceMotion() {
    let motion = useRef(new DeviceMotion());
    let previousMotion = useRef(new DeviceMotion());

    /**
     * @type {React.MutableRefObject<{
     *   position: THREE.Vector3,
     *   velocity: THREE.Vector3,
     *   rotation: THREE.Vector3,
     *   rotationRate: THREE.Vector3,
     *   acceleration: THREE.Vector3,
     *   movementDirection: THREE.Vector3,
     *   interval: number,
     *   hasMoved: boolean,
     *   hasRotated: boolean,
     * }|null>}
     */
    let motionDiff = useRef(null);

    let isMoving = useRef(false);

    const resetDelta = useCallback(() => {
        motion.current.resetDelta();
    }, []);

    useInterval(() => {
        motionDiff.current = motion.current.diff(previousMotion.current, {
            hasMovedThreshold: 0.001,
            hasRotatedThreshold: 5,
        });

        if (motionDiff.current.hasMoved && !motionDiff.current.hasRotated) {
            isMoving.current = true;
        } else {
            isMoving.current = false;
        }

        previousMotion.current = motion.current.clone();
    }, 100);

    const deviceMotionHandler = useCallback((event) => {
        let x = event.acceleration.x;
        let y = event.acceleration.y;
        let z = event.acceleration.z;

        let alpha = event.rotationRate.alpha;
        let beta = event.rotationRate.beta;
        let gamma = event.rotationRate.gamma;

        motion.current.accelerate(x, y, z, alpha, beta, gamma, event.timeStamp);
    }, []);

    const deviceOrientationHandler = useCallback((event) => {
        const alpha = event.alpha;
        const beta = event.beta;
        const gamma = event.gamma;

        motion.current.rotate(alpha, beta, gamma);
    }, []);

    useEffect(() => {
        const currentMotionHandler = deviceMotionHandler;
        const currentOrientationHandler = deviceOrientationHandler;

        if (window.DeviceMotionEvent) {
            window.addEventListener('devicemotion', currentMotionHandler);
        }

        if (window.DeviceOrientationEvent) {
            window.addEventListener('deviceorientation', currentOrientationHandler, true);
        }

        return () => {
            if (window.DeviceMotionEvent) {
                window.removeEventListener('devicemotion', currentMotionHandler);
            }

            if (window.DeviceOrientationEvent) {
                window.removeEventListener('deviceorientation', currentOrientationHandler, true);
            }
        };
    }, [deviceMotionHandler, deviceOrientationHandler]);

    return {
        motion,
        resetDelta,
        isMoving,
        motionDiff,
    };
}

export { useDeviceMotion };
