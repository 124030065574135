import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { models } from '../../config/game';
import { Fork } from './fork';
import { Ladle } from './ladle';
import { MoccaSpoon } from './moccaSpoon';
import { PanTurner } from './panTurner';
import { Spoon } from './spoon';

const Handle = ({ visible, onMount, onUnmount }) => {
    const model = useSelector((state) => state.game.model);

    return useMemo(() => {
        // Adding all and switch by using _visible_ makes problem with the physic objects
        switch (model) {
            case models.ladle:
                return <Ladle visible={visible} onMount={onMount} onUnmount={onUnmount} />;
            case models.moccaSpoon:
                return <MoccaSpoon visible={visible} onMount={onMount} onUnmount={onUnmount} />;
            case models.fork:
                return <Fork visible={visible} onMount={onMount} onUnmount={onUnmount} />;
            case models.panTurner:
                return <PanTurner visible={visible} onMount={onMount} onUnmount={onUnmount} />;
            case models.spoon:
            default:
                return <Spoon visible={visible} onMount={onMount} onUnmount={onUnmount} />;
        }
    }, [model, onMount, onUnmount, visible]);
};

export { Handle };
