import React, { useMemo } from 'react';
import Webcam from '@rrelmy/react-webcam';
import styles from './camera-overlay.module.scss';
import { getUserMediaConstraints } from '../../utils/game-utils';
import { useSelector } from 'react-redux';

const handleUserMediaError = (e) => {
    console.error('Error get user media', e);
};

const CameraOverlay = () => {
    const constraints = useMemo(() => getUserMediaConstraints(), []);
    const inverted = useSelector((state) => state.game.inverted);

    const classNames = useMemo(() => {
        const names = [styles.videoContainer];
        if (inverted) {
            names.push(styles.inverted);
        }

        return names.join(' ');
    }, [inverted]);

    return (
        <div className={classNames}>
            <Webcam
                audio={constraints.audio}
                videoConstraints={constraints.video}
                onUserMediaError={handleUserMediaError}
            />
        </div>
    );
};

export { CameraOverlay };
