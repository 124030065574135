import React, { useEffect, useMemo, useContext } from 'react';
import { MeshStandardMaterial } from 'three';
import { deg2rad } from '../../utils/geometry';
import { Tool } from './tool';
import { AssetsContext, disposeModel } from '../Gameplay/AssetsContext';

function createShapes() {
    const borderHeight = 0.1;
    const boxMaterial = {
        friction: 1,
        restitution: 0,
    };

    return [
        {
            // plate
            type: 'Box',
            args: [2.5, 0.25, 5],
            position: [0, -4.3, -0.1],
            material: boxMaterial,
        },
        {
            // handle
            type: 'Box',
            args: [0.8, 0.4, 7],
            position: [0.1, -4.2, 6],
            rotation: [0, 0, 0],
            material: boxMaterial,
        },
        {
            // left
            type: 'Box',
            args: [0.5, borderHeight, 4.5],
            position: [-1.3, -3.9, -0.1],
            rotation: [0, deg2rad(7), deg2rad(-20)],
            material: boxMaterial,
        },
        {
            // right
            type: 'Box',
            args: [0.5, borderHeight, 4.5],
            position: [1.3, -3.9, -0.1],
            rotation: [0, deg2rad(-4), deg2rad(20)],
            material: boxMaterial,
        },
        {
            // bottom (near player)
            type: 'Box',
            args: [2.5, borderHeight, 0.75],
            position: [0, -4, 2.35],
            rotation: [deg2rad(-45), 0, 0],
            material: boxMaterial,
        },
        {
            // top
            type: 'Box',
            args: [3.5, borderHeight, 1.25],
            position: [0, -3.9, -2],
            rotation: [deg2rad(15), 0, 0],
            material: boxMaterial,
        },
    ];
}

const PanTurner = (props) => {
    const {
        models: { panTurner: originalModel },
        ormMaps: { panTurner: ormMap },
        textures: { panTurner: texture },
    } = useContext(AssetsContext);

    const model = useMemo(() => originalModel.clone(true), [originalModel]);

    // Prepare and assign material
    useEffect(() => {
        /**
         * @type {Object3D}
         */
        let mesh = null;
        model.traverse((node) => {
            if (node.isMesh) {
                mesh = node;
            }
        });

        if (mesh.position.x === 0) {
            mesh.position.set(0.2, -4, 8); // Move center to 0,0
        }

        mesh.material = new MeshStandardMaterial({
            color: mesh.material.color,
            map: texture,
            metalness: 1, // required to make metalnessMap working
            envMapIntensity: 0.5,

            aoMap: ormMap,
            roughnessMap: ormMap,
            metalnessMap: ormMap,
        });
    }, [model, texture, ormMap]);

    const shapes = useMemo(() => createShapes(), []);

    useEffect(() => {
        const currentModel = model;
        return () => {
            disposeModel(currentModel);
        };
    }, [model]);

    return <Tool {...props} shapes={shapes} model={model} />;
};

export { PanTurner };
