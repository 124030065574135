import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './messages.module.scss';
import { resetMessage } from '../../model/game';

const Messages = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let message = useSelector((state) => state.game.message);
    useEffect(() => {
        if (message) {
            setTimeout(() => {
                dispatch(resetMessage());
            }, 5000);
        }
    }, [dispatch, message]);

    return message ? (
        <div className={styles.messageContainer}>
            <div className={styles.message}>{t(message)}</div>
        </div>
    ) : null;
};
export { Messages };
