import { Vector3 } from 'three';

class DeviceMotion {
    constructor() {
        this.position = new Vector3(0, 0, 0);
        this.positionDelta = new Vector3(0, 0, 0);
        this.velocity = new Vector3(0, 0, 0);
        this.acceleration = new Vector3(0, 0, 0);
        this.rotation = new Vector3(0, 0, 0);
        this.accumulatedRotation = new Vector3(0, 0, 0);
        this.accumulatedRotationDelta = new Vector3(0, 0, 0);
        this.rotationRate = new Vector3(0, 0, 0);
        this.lastMeasurement = null;

        this.minAccelerationThreshold = 0.5;
        this.minRotationThreshold = 0.5;
        this.minVelocityThreshold = 0.001;
        this.velocityFriction = 0.85;
    }

    /**
     *
     * @param {number} timestamp
     * @returns {number}
     */
    getIntervalSinceLastMeasurement(timestamp) {
        // calculate elapsed time between measurements and convert them from s to mos
        return (timestamp - this.lastMeasurement) / 1000;
    }

    /**
     *
     * @param {Vector3} acceleration
     * @param {number} timestamp
     * @returns {Vector3}
     */
    getNewVelocity(acceleration, timestamp) {
        const interval = this.getIntervalSinceLastMeasurement(timestamp);
        const newVelocity = this.velocity.clone();

        if (!interval) {
            return newVelocity;
        }

        return newVelocity.add(
            new Vector3(acceleration.x * interval, acceleration.y * interval, acceleration.z * interval),
        );
    }

    /**
     *
     * @param {Vector3} velocity
     * @param {number} timestamp
     * @returns {Vector3}
     */
    getNewPosition(velocity, timestamp) {
        const interval = this.getIntervalSinceLastMeasurement(timestamp);
        const newPosition = this.position.clone();

        if (!interval) {
            return newPosition;
        }

        return newPosition.add(new Vector3(velocity.x * interval, velocity.y * interval, velocity.z * interval));
    }

    /**
     *
     * @param {Vector3}  rotationRate
     * @param {number}  timestamp
     * @returns {Vector3}
     */
    getNewAccumulatedRotation(rotationRate, timestamp) {
        const interval = this.getIntervalSinceLastMeasurement(timestamp);
        const newAccumulatedRotation = this.accumulatedRotation.clone();

        if (!interval) {
            return newAccumulatedRotation;
        }

        return newAccumulatedRotation.add(
            new Vector3(rotationRate.x * interval, rotationRate.y * interval, rotationRate.z * interval),
        );
    }

    /**
     *
     * @param {Vector3} position
     * @returns {Vector3}
     */
    getNewPositionDelta(position) {
        return position.clone().sub(this.position).add(this.positionDelta);
    }

    /**
     *
     * @param {Vector3} accumulatedRotation
     * @returns {Vector3}
     */
    getNewAccumulatedRotationDelta(accumulatedRotation) {
        return accumulatedRotation.clone().sub(this.accumulatedRotation).add(this.accumulatedRotationDelta);
    }

    /**
     *
     * @param {Vector3} vec
     * @param {number} threshold
     * @param {boolean} abs
     * @returns {Vector3}
     */
    static applyThreshold(vec, threshold, abs = true) {
        const distance = abs ? Math.abs(vec.length()) : vec.length();

        if (distance > threshold) {
            return vec;
        }

        return new Vector3(0, 0, 0);
    }

    /**
     *
     * @param {number} x
     * @param {number} y
     * @param {number} z
     * @param {number} alpha
     * @param {number} beta
     * @param {number} gamma
     * @param {number} timestamp
     */
    accelerate(x, y, z, alpha, beta, gamma, timestamp) {
        const acceleration = DeviceMotion.applyThreshold(new Vector3(x, y, z), this.minAccelerationThreshold);

        const rotationRate = DeviceMotion.applyThreshold(new Vector3(alpha, beta, gamma), this.minRotationThreshold);

        const velocity = DeviceMotion.applyThreshold(
            this.getNewVelocity(acceleration, timestamp),
            this.minVelocityThreshold,
        );
        const position = this.getNewPosition(this.velocity, timestamp);
        const positionDelta = this.getNewPositionDelta(position);

        const accumulatedRotation = this.getNewAccumulatedRotation(rotationRate, timestamp);

        const accumulatedRotationDelta = this.getNewAccumulatedRotationDelta(accumulatedRotation);

        this.acceleration = acceleration;
        this.velocity = velocity.multiplyScalar(this.velocityFriction); // add friction
        this.position = position;
        this.positionDelta = positionDelta;
        this.accumulatedRotation = accumulatedRotation;
        this.accumulatedRotationDelta = accumulatedRotationDelta;
        this.rotationRate = rotationRate;

        this.lastMeasurement = timestamp;
    }

    /**
     *
     * @param {number} alpha
     * @param {number} beta
     * @param {number} gamma
     */
    rotate(alpha, beta, gamma) {
        this.rotation = new Vector3(alpha, beta, gamma);
    }

    /**
     *
     * @returns {DeviceMotion}
     */
    clone() {
        const clonedMotion = new DeviceMotion();

        clonedMotion.position = this.position.clone();
        clonedMotion.positionDelta = this.positionDelta.clone();
        clonedMotion.velocity = this.velocity.clone();
        clonedMotion.acceleration = this.acceleration.clone();
        clonedMotion.rotation = this.rotation.clone();
        clonedMotion.accumulatedRotation = this.accumulatedRotation.clone();
        clonedMotion.accumulatedRotationDelta = this.accumulatedRotationDelta.clone();
        clonedMotion.rotationRate = this.rotationRate.clone();
        clonedMotion.lastMeasurement = this.lastMeasurement;

        return clonedMotion;
    }

    /**
     *
     * @param {DeviceMotion} motion
     * @returns {Vector3}
     */
    diffPosition(motion) {
        return this.position.clone().sub(motion.position);
    }

    /**
     *
     * @param {DeviceMotion} motion
     * @returns {Vector3}
     */
    diffVelocity(motion) {
        return this.velocity.clone().sub(motion.velocity);
    }

    /**
     *
     * @param {DeviceMotion} motion
     * @returns {Vector3}
     */
    diffRotation(motion) {
        return this.rotation.clone().sub(motion.rotation);
    }

    /**
     *
     * @param {DeviceMotion} motion
     * @returns {Vector3}
     */
    diffRotationRate(motion) {
        return this.rotationRate.clone().sub(motion.rotationRate);
    }

    /**
     *
     * @param {DeviceMotion} motion
     * @returns {Vector3}
     */
    diffAcceleration(motion) {
        return this.acceleration.clone().sub(motion.acceleration);
    }
    /**
     *
     * @param {DeviceMotion} motion
     * @returns {Vector3}
     */
    diffAccumulatedRotation(motion) {
        return this.accumulatedRotation.clone().sub(motion.accumulatedRotation);
    }

    /**
     *
     * @param {DeviceMotion} motion
     * @param {{
     *   hasMovedThreshold?: number,
     *   hasMovedCompareAbsolute?: number,
     *   hasRotatedThreshold?: number,
     *   hasRotatedCompareAbsolute?: number,
     * }} diffOptions
     * @returns {{
     *   position: Vector3,
     *   velocity: Vector3,
     *   rotation: Vector3,
     *   accumulatedRotation: Vector3,
     *   rotationRate: Vector3,
     *   acceleration: Vector3,
     *   movementDirection: Vector3,
     *   interval: number,
     *   hasMoved: boolean,
     *   hasRotated: boolean,
     * }}
     */
    diff(motion, diffOptions = {}) {
        const hasMovedThreshold = diffOptions.hasMovedThreshold ?? 0;
        const hasMovedCompareAbsolute = diffOptions.hasMovedCompareAbsolute ?? true;
        const hasRotatedThreshold = diffOptions.hasRotatedThreshold ?? 0;
        const hasRotatedCompareAbsolute = diffOptions.hasRotatedCompareAbsolute ?? false;

        const positionDiff = this.diffPosition(motion);
        const rotationDiff = this.diffRotation(motion);

        const movedDistance = hasMovedCompareAbsolute ? Math.abs(positionDiff.length()) : positionDiff.length();
        const rotatedAmount = hasRotatedCompareAbsolute ? Math.abs(rotationDiff.length()) : rotationDiff.length();

        const hasMoved = movedDistance > hasMovedThreshold;
        const hasRotated = rotatedAmount > hasRotatedThreshold;

        return {
            position: positionDiff,
            velocity: this.diffVelocity(motion),
            rotation: rotationDiff,
            rotationRate: this.diffRotationRate(motion),
            accumulatedRotation: this.diffAccumulatedRotation(motion),
            acceleration: this.diffAcceleration(motion),
            movementDirection: positionDiff.clone().normalize(),
            interval: this.lastMeasurement - motion.lastMeasurement,
            hasMoved,
            hasRotated,
        };
    }

    resetDelta() {
        this.positionDelta = new Vector3(0, 0, 0);
        this.accumulatedRotationDelta = new Vector3(0, 0, 0);
    }
}

export { DeviceMotion };
