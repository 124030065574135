import { createSlice } from '@reduxjs/toolkit';
import { generateThemeEvents, shuffleArray } from '../utils/game-utils';
import {
    mapPercentagePositionToWorld,
    isWithinRange,
    getRandomAvailablePosition,
    isWithinSight,
    angleBetweenPoints,
    angleDeviation,
    deg2rad,
} from '../utils/geometry';
import {
    gameDuration,
    stages,
    models,
    boosters,
    boosterPositions,
    numberOfVisibleBoosters,
    minDistanceToHandleForNewBooster,
    weeklyThemes,
    eventType,
    timeToNotifyUserToCollectBoosters,
} from '../config/game';

/**
 * Helper to apply physics booster to the state
 */
function applyPhysicsBooster(state, booster, startTime) {
    const key = booster.effect.type;

    // extend existing duration if active
    if (state[key + 'Active']) {
        state[key + 'Duration'] += booster.duration;
        return;
    }

    // start new booster
    state[key + 'Active'] = true;
    state[key + 'Start'] = startTime;
    state[key + 'Duration'] = booster.duration;

    if (key === 'physicLadle') {
        state.baseModel = state.model; // save current tool to base
        state.model = models.ladle;
    }
}

function _resetGameState(state) {
    state.ready = false;
    state.running = false;
    state.finished = false;
    state.dropped = false;
    state.tickCount = 0;

    state.model = models.spoon;
    state.baseModel = models.spoon;
    state.inverted = false;
    state.invertedUntil = null;
    state.weekIndex = 0;

    state.startTime = null;
    state.endTime = null;
    state.points = 0;
    state.stage = stages[0];
    state.stageCounter = 0;
    state.stageIndex = 0;
    state.modifier = null;
    state.modifierDuration = 0;
    state.modifierStart = null;
    state.modifierQueue = [];

    state.physicsBoosterQueue = [];
    state.physicLadleActive = false;

    state.windAction = null;
    state.windAppearedOnce = false;
    state.isPositionCorrect = false;

    state.message = null;
    state.animation = null;
    state.countdownAnimation = false;
    state.eventAnimation = null;

    state.hasCollectedBooster = false;
    state.noBoosterVisible = false;
    state.nextBoosterDirection = null;
}

export const modelGame = createSlice({
    name: 'game',
    initialState: {
        testMode: true,
        developmentMode: process.env.NODE_ENV === 'development',

        debug: {
            showFpsStats: false,
            showWorld: false,
            showCannonBoxes: false, // reload required after change
            disableCamera: process.env.NODE_ENV === 'development',
            lowPerf: false, // DO not use in production, only for testing! Only perf setting is the pixelRatio based on gpu tier
        },

        ready: false,
        running: false,
        finished: false,
        dropped: false,
        tickCount: 0,
        startTime: null,
        endTime: null,
        points: 0,
        stage: stages[0],
        stageCounter: 0,
        stageIndex: 0,
        message: null,
        animation: null,
        countdownAnimation: false,
        weekIndex: 0,
        playType: 'single',

        // weekly theme
        theme: weeklyThemes[0],
        themeEvents: [],
        eventAnimation: null, // wind-left | wind-right | obstruct

        // list of boosters that are visible within the game
        boosters: [],
        // list of all unused boosters, randomized to be able to pick
        availableBoosters: [],
        lastBoosterCollectionTime: 0,
        collectNotified: false,
        modifier: null,
        modifierDuration: 0,
        modifierStart: null,
        modifierQueue: [],

        // physics
        physicsBoosterQueue: [],
        // ladle
        physicLadleActive: false,
        physicLadleStart: null,
        physicLadleDuration: 0,

        baseModel: models.spoon,
        model: models.spoon,
        windAction: null,
        windAppearedOnce: false,
        inverted: false,
        invertedUntil: null,

        handlePosition: [0, 0, 0],
        cameraRotation: { x: 0, y: 0, z: 0 },

        isPositionCorrect: false,

        hasCollectedBooster: false,
        noBoosterVisible: false,
        nextBoosterDirection: null,
    },
    reducers: {
        toggleDebug: (state, { payload }) => {
            state.debug[payload] = !state.debug[payload];
        },
        resetMessage: (state) => {
            state.message = null;
        },
        prepareGame: (state, action) => {
            state.theme = weeklyThemes[state.weekIndex];
            state.ready = true;
        },
        setPositionCorrect: (state, action) => {
            const value = action.payload;
            state.isPositionCorrect = value;
            if (!state.running) {
                state.countdownAnimation = value;
            }
        },
        setHandlePosition: (state, action) => {
            const position = action.payload;
            if (
                state.handlePosition[0] === position[0] &&
                state.handlePosition[1] === position[1] &&
                state.handlePosition[2] === position[2]
            ) {
                return;
            }

            state.handlePosition = position;
        },
        setCameraRotation: (state, action) => {
            let { x, y, z } = action.payload;
            x = Math.round(x * 50) / 50;
            y = Math.round(y * 50) / 50;
            z = Math.round(z * 50) / 50;

            if (state.cameraRotation.x === x && state.cameraRotation.y === y && state.cameraRotation.z === z) {
                return;
            }

            state.cameraRotation = { x, y, z };
        },
        resetGameState: (state) => _resetGameState(state),
        start: (state, action) => {
            // Prepare boosters: use amountPerRound settings then shuffle
            let boosterId = 0;
            const availableBoostersForRound = boosters.flatMap((booster) => {
                return [...Array(booster.amountPerRound)].map(() => ({
                    ...booster,
                    id: ++boosterId, // create unique id per booster
                }));
            });
            const availableBoosters = [...shuffleArray(availableBoostersForRound)];

            /**
             * Prepare list of initial booster positions
             *
             * 1. map percentage to world coordinates
             * 2. remove positions near the handle
             * 3. sort visible positions to the front of the array to use them first (usually just 2)
             */
            const intialPositions = shuffleArray(boosterPositions)
                .map(mapPercentagePositionToWorld) // 1.
                .filter((pos) => !isWithinRange(pos, state.handlePosition, minDistanceToHandleForNewBooster)) // 2.
                .sort((posA, posB) => (isWithinSight(posA, state.cameraRotation.y) ? -1 : 0)); // 3.

            const initialBoosters = availableBoosters.splice(null, numberOfVisibleBoosters).map((booster, index) => {
                return {
                    booster,
                    position: intialPositions[index],
                };
            });

            _resetGameState(state);
            state.running = true;
            state.startTime = Date.now();

            // weekly theme
            state.themeEvents = generateThemeEvents(state.theme, gameDuration);

            state.boosters = initialBoosters;
            state.availableBoosters = availableBoosters;
        },
        stop: (state, action) => {
            state.running = false;
            state.endTime = Date.now();
            state.availableBoosters = [];
            state.modifier = null;
            state.boosters = null;
            state.dropped = action.payload === true ? true : false;
        },
        finish: (state) => {
            state.finished = true;
        },
        clearAnimation: (state, action) => {
            state.animation = null;
        },
        clearEventAnimation: (state, action) => {
            state.eventAnimation = null;
        },
        collectBooster: (state, action) => {
            if (!state.running) {
                return;
            }

            state.hasCollectedBooster = true;

            let newBooster = null;
            let booster = action.payload;
            const collectedBoosterId = booster.id; // required to remove booster from list if switched

            // OS2020-729: prevent ladle if weekly modifier is active
            const weeklyModifierActive = state.windAction || state.inverted;
            if (booster.effect.type === 'physicLadle' && weeklyModifierActive && state.availableBoosters.length > 0) {
                console.info('Switch ladle booster');
                // switch collected booster with another (sneeky :smirk:)
                // find first non ladle booster
                const index = state.availableBoosters.findIndex((b) => b.effect.type !== 'physicLadle');

                // edge-case: if there are no more other boosters available the ladle will not be replaced
                if (index > -1) {
                    // remove the new booster from the available list
                    const replacementBooster = state.availableBoosters.splice(index, 1)[0];

                    // the ladle booster will be directly placed again in the room
                    newBooster = booster;
                    booster = replacementBooster;
                }
            }

            // Apply effect
            switch (booster.effect.type) {
                case 'pointModifier':
                    // Booster is always added to queue, tick() will take care of activation
                    state.modifierQueue = [...state.modifierQueue, booster];
                    break;
                case 'addPoints':
                    state.points = state.points + booster.effect.value;
                    state.animation = booster.name;
                    break;
                case 'physicLadle':
                    state.physicsBoosterQueue.push(booster);
                    break;
                default:
                    console.error('Unknown booster', booster);
            }
            state.lastBoosterCollectionTime = Date.now() - state.startTime;
            state.collectNotified = false;
            // remove collected booster from list
            state.boosters = state.boosters.filter((item) => item.booster.id !== collectedBoosterId);

            // add a new booster
            if (state.boosters.length < numberOfVisibleBoosters && state.availableBoosters.length > 0) {
                if (!newBooster) {
                    newBooster = state.availableBoosters.shift();
                }

                const position = getRandomAvailablePosition(
                    boosterPositions.filter(({ id }) => !state.boosters.some(({ position }) => position.id === id)), // filter out used position
                    state.handlePosition,
                );

                state.boosters.push({
                    booster: newBooster,
                    position,
                });
            }
        },
        changeModel: (state) => {
            // TODO remove, debug code
            const keys = Object.keys(models);
            let nextIndex = keys.indexOf(state.model) + 1;
            if (nextIndex === keys.length) {
                nextIndex = 0;
            }

            const model = Object.values(models)[nextIndex];

            state.baseModel = model;
            state.model = model;
        },
        clearWindAction: (state) => {
            state.windAction = null;
        },
        changeWeekMode: (state, action) => {
            state.weekIndex = action.payload;
        },
        debugInversion: (state) => {
            // TODO remove, debug code
            state.inverted = !state.inverted;
            state.invertedUntil = 999999999; // forever
        },
        tick: (state) => {
            let { stage, animation, modifier, modifierDuration, modifierStart, tickCount, windAppearedOnce } = state;
            const weeklyPointModifier = state.theme.weeklyModifier;
            const newPoints = stage.points * (modifier ? modifier : 1) * weeklyPointModifier;
            const duration = Date.now() - state.startTime;
            const updates = {
                points: (state.points += newPoints),
                tickCount: state.tickCount + 1,
            };

            // stop
            if (duration >= gameDuration || updates.tickCount >= 1200) {
                state.points = state.points += newPoints;
                state.running = false;
                state.endTime = Date.now();
                state.availableBoosters = [];
                return;
            }

            // theme events
            if (state.themeEvents.length > 0 && state.themeEvents[0].at <= duration) {
                const themeEvent = state.themeEvents.shift(); // remove current event from event list

                switch (themeEvent.type) {
                    case eventType.wind:
                        if (state.physicLadleActive || !state.hasCollectedBooster) {
                            break;
                        }

                        console.info('Wind from ', themeEvent.option);
                        updates.eventAnimation = `${themeEvent.type}-${themeEvent.option}`;
                        updates.windAction = {
                            ...themeEvent,
                            sensitivityModifier: state.stage.sensitivityModifier,
                        };
                        // fix OS2020-720: message about Wind event should be displayed only first time
                        if (!windAppearedOnce) {
                            updates.message = 'game.hud.messages.wind';
                            updates.windAppearedOnce = true;
                        }
                        break;
                    case eventType.obstruct:
                        if (state.physicLadleActive) {
                            break;
                        }

                        console.info('Sun');
                        updates.eventAnimation = `${themeEvent.type}`;
                        updates.message = 'game.hud.messages.sun';
                        break;
                    case eventType.tool:
                        console.info('Change to ', themeEvent.option);

                        updates.baseModel = themeEvent.option;
                        if (state.model === state.baseModel) {
                            // if ladle is not active switch model also
                            updates.model = themeEvent.option;
                        }
                        updates.message = 'game.hud.messages.tool';
                        break;
                    case eventType.invert:
                        console.info('Invert');
                        updates.message = 'game.hud.messages.invert';
                        state.inverted = true;
                        state.invertedUntil = duration + themeEvent.duration;
                        break;
                    default:
                        console.error('unknown event type ', themeEvent.type);
                }
            }

            // reset inversion of controls
            if (state.inverted && state.invertedUntil <= duration) {
                state.inverted = false;
            }

            // apply new modif iers
            if (state.modifierQueue.length > 0) {
                if (!modifierStart) {
                    modifierStart = duration;
                }

                for (const m of state.modifierQueue) {
                    if (m.effect.value > modifier) {
                        modifier = m.effect.value;
                    }
                    animation = `${modifier}x`;
                    modifierDuration += m.duration;
                }

                updates.modifierQueue = [];
                updates.modifier = modifier;
                updates.animation = animation;
                updates.modifierDuration = modifierDuration;
                updates.modifierStart = modifierStart;
            }

            // remove modifier
            if (modifier && modifierStart + modifierDuration <= duration) {
                updates.modifier = modifier = null;
                updates.modifierDuration = modifierDuration = 0;
                updates.modifierStart = modifierStart = null;
            }

            // physics boosters
            if (state.physicsBoosterQueue.length > 0) {
                for (const booster of state.physicsBoosterQueue) {
                    applyPhysicsBooster(state, booster, duration);
                }

                updates.physicsBoosterQueue = [];
            }

            if (state.physicLadleActive && state.physicLadleStart + state.physicLadleDuration <= duration) {
                updates.physicLadleActive = false;
                updates.physicLadleDuration = 0;
                updates.physicLadleStart = null;

                // restore normal model
                updates.model = state.baseModel;
            }

            // check for booster visibility
            // only check every second 10 ticks (1 tick = 100ms)
            if (tickCount % 10 === 0) {
                const handle = { x: state.handlePosition[0], y: state.handlePosition[2] };
                const boosterDeviations = state.boosters.map(({ position }) =>
                    angleDeviation(angleBetweenPoints(position, handle), state.cameraRotation.y),
                );

                const visibleAngle = deg2rad(35);
                updates.noBoosterVisible = !boosterDeviations.some((deviation) => Math.abs(deviation) < visibleAngle);
                if (updates.noBoosterVisible) {
                    const minDeviation = boosterDeviations.sort((a, b) => Math.abs(a) - Math.abs(b))[0];
                    updates.nextBoosterDirection = minDeviation > 0 ? 'left' : 'right';
                }
            }

            // message for user to start collecting booster if not yet
            if (duration - state.lastBoosterCollectionTime >= timeToNotifyUserToCollectBoosters) {
                if (state.collectNotified === false) {
                    updates.message = 'game.hud.messages.collect';
                    updates.collectNotified = true;
                }
            }

            // update stage
            const stageLimit = duration - state.stageCounter;
            if (stageLimit > state.stage.duration) {
                stage = stages[state.stageIndex + 1];

                updates.stageCounter = state.stageCounter + state.stage.duration;
                updates.stageIndex = state.stageIndex + 1;
                updates.stage = stage;
            }

            Object.keys(updates).forEach((key) => {
                state[key] = updates[key];
            });
        },
    },
});

export const {
    prepareGame,
    setPositionCorrect,
    setHandlePosition,
    setCameraRotation,
    start,
    debugInversion,
    clearWindAction,
    stop,
    finish,
    collectBooster,
    clearAnimation,
    clearEventAnimation,
    changeModel,
    tick,
    toggleDebug,
    resetMessage,
    changeWeekMode,
} = modelGame.actions;

export const gameFinish = () => async (dispatch, getState) => {
    await Promise.all([dispatch(finish())]);
    // reset game state after submission
    dispatch(modelGame.actions.resetGameState());
};

export default modelGame;
