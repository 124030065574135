import { useEffect, useState } from 'react';
import { isIOS13 } from 'react-device-detect';

/**
 * This hook is only used for local development to have a requestPermission check!
 * It can be removed on production.
 */
const useDeviceCheck = () => {
    const [deviceMotionSensor, setDeviceMotion] = useState(true);
    const [deviceOrientationSensor, setDeviceOrientation] = useState(true);
    const [deviceMotionGranted, setDeviceMotionGranted] = useState(false);
    const [deviceOrientationGranted, setDeviceOrientationGranted] = useState(false);

    useEffect(() => {
        // Check if is IOS 13 when page loads.
        if (
            window.DeviceMotionEvent &&
            typeof window.DeviceMotionEvent.requestPermission === 'function' &&
            window.DeviceOrientationEvent &&
            typeof window.DeviceOrientationEvent.requestPermission === 'function'
        ) {
            setDeviceMotion(true);
            const clickRequestDeviceMotionEvent = () => {
                window.DeviceOrientationEvent.requestPermission()
                    .then((response) => {
                        if (response === 'granted') {
                            console.log('DeviceOrientation permission granted');
                            setDeviceOrientationGranted(true);
                            window.DeviceMotionEvent.requestPermission()
                                .then((response) => {
                                    if (response === 'granted') {
                                        setDeviceMotionGranted(true);
                                        const banner = document.getElementById('motionBanner');
                                        banner.remove();
                                        window.location.reload();
                                    } else {
                                        console.log('DeviceMotion permissions not granted.', response);
                                    }
                                })
                                .catch((e) => {
                                    console.error('Cannot get DeviceMotion permissions', e);
                                });
                        } else {
                            console.log('DeviceOrientation permissions not granted', response);
                        }
                    })
                    .catch((e) => {
                        console.error('Cannot get DeviceOrientation permissions', e);
                    });
            };

            window.DeviceMotionEvent.requestPermission()
                .then(() => {
                    setDeviceMotionGranted(true);
                    window.DeviceOrientationEvent.requestPermission().then(() => {
                        setDeviceOrientationGranted(true);
                    });
                })
                .catch((e) => {
                    console.error(e);
                    const banner = document.createElement('div');
                    banner.id = 'motionBanner';
                    banner.style.cssText =
                        'z-index: 100000; position: absolute; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; top: 0; background-color:#000; color: #fff';
                    banner.innerHTML = `<button type="button" style="background: #ff6600; color: #fff; border: 0; padding: 1em 2em; font-size: 1.2em;">Click here to enable DeviceMotion</button>`;
                    banner.onclick = clickRequestDeviceMotionEvent; // You NEED to bind the function into a onClick event. An artificial 'onClick' will NOT work.
                    document.querySelector('body').appendChild(banner);
                });
        } else {
            if (isIOS13) {
                setDeviceMotion(false);
                setDeviceOrientation(false);
            }
        }

        return () => {};
    }, []);

    return {
        deviceMotionGranted,
        deviceOrientationGranted,
        deviceMotionSensor,
        deviceOrientationSensor,
    };
};

export { useDeviceCheck };
