import React, { useCallback, useEffect, useState } from 'react';
import ReactHowler from 'react-howler';
import { useSelector } from 'react-redux';
import backgroundMusic from '../../assets/sound/easter_background_music_loop.mp3';
import collectPoints from '../../assets/sound/easter_collect_points.mp3';
import collectPointsTimer from '../../assets/sound/easter_collect_points_booster_timer.mp3';
import countdown from '../../assets/sound/easter_countdown.mp3';
import gameFailed from '../../assets/sound/easter_egg_drop_round_finished.mp3';
import gameEnd from '../../assets/sound/easter_time_is_up_round_finished.mp3';

const Sounds = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [fileName, setFileName] = useState();

    const animation = useSelector((state) => state.game.animation);
    const isPositionCorrect = useSelector((state) => state.game.isPositionCorrect);
    const running = useSelector((state) => state.game.running);
    const finished = useSelector((state) => state.game.finished);
    const dropped = useSelector((state) => state.game.dropped);
    const endTime = useSelector((state) => state.game.endTime);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsPlaying(true);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        if (animation === '2x' || animation === '3x' || animation === '4x') {
            setFileName(collectPointsTimer);
        } else if (animation === '100' || animation === '500') {
            setFileName(collectPoints);
        } else if (!running && isPositionCorrect) {
            setFileName(countdown);
        } else if (!running && !isPositionCorrect) {
            setFileName(null);
        }
        if (!running && !finished && endTime) {
            if (dropped) {
                setFileName(gameFailed);
            } else {
                setFileName(gameEnd);
            }
        }
    }, [animation, dropped, endTime, finished, isPositionCorrect, running]);

    const endCallback = useCallback(() => {
        setFileName(null);
    }, []);

    return (
        <>
            <ReactHowler preload volume={0.2} src={backgroundMusic} playing={isPlaying} loop />
            {fileName && <ReactHowler preload src={fileName} playing={true} onEnd={endCallback} />}
        </>
    );
};

export { Sounds };
